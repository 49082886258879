import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import { useJourneyContext } from '../Journey/JourneyContext';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '3.75rem',
    width: '75%',
    marginLeft: '3.3rem',
    position: 'relative',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '0.35rem',
    transition: '0.3s ease-in-out all',
    paddingLeft: '25px',
  },
  index: {
    height: '35px',
    width: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '18px',
    position: 'absolute',
    left: '-16px',
    top: '10px',
    transition: '0.3s ease-in-out all',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
  },
  delete: {
    padding: '5px !important',
    borderRadius: '50% !important',
    zIndex: 100,
  },
}));

interface IJourneyStageWrapper {
  selected: boolean;
  index: number;
  onClick: () => void;
  children: React.ReactNode;
  locked?: boolean | undefined;
}

const JourneyStageWrapper: FunctionComponent<IJourneyStageWrapper> = (
  props,
) => {
  const { selected, index, onClick, children, locked } = props;
  const { isEditJourneyContext } = useJourneyContext();
  const theme = useTheme();
  const classes = useStyles({ selected, isEditJourneyContext });
  if (locked) {
    return (
      <Box
        className={classes.root}
        sx={{
          cursor: 'default',
          border: `2px solid ${
            selected
              ? theme.palette.primary.main
              : isEditJourneyContext
              ? theme.palette.grey
              : '#E0F2F1'
          }`,
          backgroundColor:
            isEditJourneyContext && !selected ? '#d4d4d4' : '#E0F2F1',
        }}>
        <Box
          className={classes.index}
          sx={{
            backgroundColor: selected
              ? theme.palette.primary.main
              : isEditJourneyContext
              ? '#d4d4d4'
              : '#E0F2F1',
            color: selected
              ? 'white'
              : isEditJourneyContext
              ? 'grey'
              : theme.palette.primary.main,
          }}>
          {index + 1}
        </Box>
        {children}
      </Box>
    );
  }

  return (
    <Box
      className={classes.root}
      onClick={onClick}
      tabIndex={index}
      sx={{
        cursor: 'pointer',
        border: `2px solid ${
          selected
            ? theme.palette.primary.main
            : isEditJourneyContext
            ? theme.palette.grey
            : '#E0F2F1'
        }`,
        backgroundColor:
          isEditJourneyContext && !selected ? '#d4d4d4' : '#E0F2F1',
      }}>
      <Box
        className={classes.index}
        sx={{
          backgroundColor: selected
            ? theme.palette.primary.main
            : isEditJourneyContext
            ? '#d4d4d4'
            : '#E0F2F1',
          color: selected
            ? 'white'
            : isEditJourneyContext
            ? 'grey'
            : theme.palette.primary.main,
        }}>
        {index + 1}
      </Box>
      {children}
    </Box>
  );
};

export default JourneyStageWrapper;
