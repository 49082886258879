import {
  Check,
  Edit,
  Email,
  Language,
  LinkedIn,
  LocalPhone,
  MoreVert,
  VideoLabel,
} from '@mui/icons-material';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import CommonButton from 'components/common/CommonButton';
import InformationModal from 'components/common/Modal/InformationModal';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import ShowOptionsButton from 'components/common/MoreOptionsButton/ShowOptionsButton';
import React, {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { naturalPallete } from 'theme';
import { IListButton, IProfile, ITag } from 'utils/models';
import { getAccount } from 'utils/storage';
import MemberEditSocialMedia from './MemberEditSocialMediaAndContacts';
import SelectDropdown from 'components/common/SelectDropdown';
import InputField from 'components/common/InputField';
import UmamiWrapper from 'components/common/UmamiWrapper';

interface IMemberHeaderSection {
  member: IProfile;
  isEditProfile: boolean;
  setIsEditProfile: Dispatch<React.SetStateAction<boolean>>;
  updateMember: (field: string, value: string | ITag[] | null) => void;
  saveEditProfile: () => void;
}

const MemberHeaderSection: FunctionComponent<IMemberHeaderSection> = (
  props,
) => {
  const {
    member,
    isEditProfile,
    setIsEditProfile,
    updateMember,
    saveEditProfile,
  } = props;

  const navigate = useNavigate();
  const { ecosystemName, profileOwnerId } = useParams();

  const { userId } = getAccount();
  const theme = useTheme();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const canEdit =
    userId.toString() === profileOwnerId || (member && member.canEditProfile);

  const companyOptionList = [
    'No primary company',
    ...member.companyNameAndLogoList.map((e) => e.name),
  ];

  const clickableIcons = [
    { link: member?.mentorLink, icon: <Language /> },
    { link: member?.linkedInLink, icon: <LinkedIn /> },
  ];

  const buttonList: IListButton[] = [];

  if (member?.email) {
    buttonList.push({
      name: member.email,
      onClick: () => window.open(`mailto:${member.email}`, '_blank'),
      icon: Email,
      color: naturalPallete.pureWhite,
      hoverColor: theme.palette.primary.contrastText,
    });
  }
  if (member?.phoneNumber) {
    buttonList.push({
      name: member.phoneNumber,
      onClick: () => window.open(`tel:${member.phoneNumber}`, '_blank'),
      icon: LocalPhone,
      color: naturalPallete.pureWhite,
      hoverColor: theme.palette.primary.contrastText,
    });
  }

  function renderEditOrDisplayPrimaryCompany(
    value: string | null,
    onChange: (value: string) => void,
  ) {
    if (isEditProfile) {
      return (
        <SelectDropdown
          value={value ?? 'No primary company'}
          onChange={onChange}
          options={companyOptionList}
          getOptionLabel={(option) => option}
          height={28}
          sx={{ width: '100%', mb: '0.2rem' }}
        />
      );
    }

    return (
      <Typography
        variant='h5'
        color={naturalPallete.pureWhite}
        sx={{ mb: '0.25rem', minHeight: '1.65rem' }}>
        {member.primaryCompany}
      </Typography>
    );
  }

  function renderEditOrDisplayTitle(
    value: string | null,
    onChange: (value: string) => void,
  ) {
    if (isEditProfile) {
      return (
        <InputField
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange(event.target.value)
          }
          height='1.75rem'
          placeholder='Title at the company'
        />
      );
    }

    return (
      <Typography
        variant='h6'
        color={naturalPallete.pureWhite}
        sx={{ fontWeight: 400, mb: '0.1rem', minHeight: '1.75rem' }}>
        {member.subtitle}
      </Typography>
    );
  }

  return (
    <Grid container sx={{ mt: '-9rem', height: '9rem' }}>
      <Grid item xs={12} md={6}>
        <Typography
          variant='h4'
          color={naturalPallete.pureWhite}
          sx={{ mb: '0.25rem' }}>
          {member.fullName}
        </Typography>

        {renderEditOrDisplayPrimaryCompany(
          member.primaryCompany,
          (value: any) => updateMember('primaryCompany', value),
        )}

        {renderEditOrDisplayTitle(member.subtitle, (value) =>
          updateMember('subtitle', value),
        )}

        <Box sx={{ ml: '-0.5rem' }}>
          {clickableIcons.map((item, i) => {
            if (!item.link) return null;
            return (
              <IconButton
                key={i}
                onClick={() => window.open(item.link, '_blank')}>
                {React.cloneElement(item.icon, {
                  sx: {
                    color: naturalPallete.pureWhite,
                    fontSize: '1.75rem',
                  },
                })}
              </IconButton>
            );
          })}
          {isEditProfile && (
            <IconButton onClick={() => setIsModalOpen(true)}>
              <MoreVert
                sx={{
                  color: naturalPallete.pureWhite,
                  fontSize: '1.75rem',
                }}
              />
            </IconButton>
          )}
        </Box>
        <InformationModal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          primaryText='Save'
          primaryOnClick={() => setIsModalOpen(false)}
          headerText={'Social media links & contact details'}>
          <Box sx={{ mt: '1rem' }}>
            <MemberEditSocialMedia
              member={member}
              updateMember={updateMember}
            />
          </Box>
        </InformationModal>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'end',
          height: '100%',
          pb: '1rem',
        }}>
        {!canEdit && member.isBookable && (
          <CommonButton
            text={'Book a meeting'}
            onClick={() =>
              navigate(
                `/ecosystem/${ecosystemName}/booking/people/book/${profileOwnerId}`,
              )
            }
            icon={<VideoLabel />}
            sx={{ mr: '1rem' }}
          />
        )}
        {canEdit && (
          <CommonButton
            text={!isEditProfile ? 'Edit profile' : 'Save'}
            onClick={
              !isEditProfile
                ? () => setIsEditProfile(!isEditProfile)
                : saveEditProfile
            }
            icon={!isEditProfile ? <Edit /> : <Check />}
            sx={{
              mr: '1rem',
              bgcolor: naturalPallete.pureWhite,
              color: naturalPallete.mainBlackFont,
              ':hover': { bgcolor: theme.palette.primary.contrastText },
            }}
          />
        )}
        <UmamiWrapper event='Get in touch'>
          <ShowOptionsButton
            popoverAnchorEl={popoverAnchorEl}
            setPopoverAnchorEl={setPopoverAnchorEl}
            text='Get in touch'
            sx={{
              mr: '2rem',
              bgcolor: naturalPallete.pureWhite,
              color: naturalPallete.mainBlackFont,
              ':hover': { bgcolor: theme.palette.primary.contrastText },
            }}>
            <MoreOptionsButtons buttonList={buttonList} />
          </ShowOptionsButton>
        </UmamiWrapper>
      </Grid>
    </Grid>
  );
};

export default MemberHeaderSection;
