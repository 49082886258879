import React, { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { acterioTheme } from 'theme';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { trackUmamiEvent } from 'utils/utils';

interface IManageEcosystemCard {
  title: string;
  description: string;
  icon: JSX.Element;
  onClick: () => void;
  secIcon?: JSX.Element;
}

const ManageEcosystemCard: FunctionComponent<IManageEcosystemCard> = (
  props,
) => {
  const { title, description, icon, onClick, secIcon } = props;

  return (
    <Box
      sx={{
        cursor: 'pointer',
        padding: '1.875rem, 1.875rem, 1.875rem, 0',
        width: '25rem',
        height: '8.75rem',
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        '&:hover': {
          border: `1px solid ${acterioTheme.main}`,
        },
        borderRadius: '12px',
        boxShadow: '0px 20px 27px 0px rgba(0, 0, 0, 0.05)',
      }}
      onClick={() => {
        onClick();
        trackUmamiEvent(title);
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'start',
        }}>
        {icon}
        <Box
          sx={{
            width: '15.625rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '1.25rem',
                color: 'rgba(19, 37, 62, 1)',
                lineHeight: '1.875rem',
              }}>
              {title}
            </Typography>
            {secIcon ? (
              <div
                style={{
                  width: '5rem',
                  overflow: 'hidden',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                {React.cloneElement(secIcon, {
                  style: { maxHeight: '100%', maxWidth: '100%' },
                })}
              </div>
            ) : (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '0.875rem',
                  lineHeight: '1rem',
                }}>
                {description}
              </Typography>
            )}
          </Box>
          <ArrowForwardIosIcon sx={{ color: acterioTheme.main }} />
        </Box>
      </Box>
    </Box>
  );
};

export default ManageEcosystemCard;
