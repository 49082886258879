import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '10px',
    width: (props: any) => `${props.width}px`,
    border: (props: any) => `1px solid ${props.borderColor || '#A6C6CB'}`,
    borderRadius: '20px',
    backgroundColor: (props: any) => props.backgroundColor || '#B9D4D8',
    display: 'flex',
    overflow: 'hidden',
  },
  inProgress: {
    borderRadius: '20px',
    backgroundColor: '#FFDC8D',
  },
  finished: {
    borderRadius: '20px',
    backgroundColor: '#A2F0CF',
    zIndex: 2,
  },
}));

const BASE_WIDTH = 200;

export enum StageProgress {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface IStageProgress {
  todo: number;
  inProgress: number;
  finished: number;
}

export const INITIAL_STAGE_PROGRESS: IStageProgress = {
  todo: 0,
  inProgress: 0,
  finished: 0,
};

export const getProgressKey = (progress: StageProgress | undefined) => {
  if (progress === StageProgress.IN_PROGRESS) return 'inProgress';
  if (progress === StageProgress.FINISHED) return 'finished';
  return 'todo';
};

interface IJourneyProgressBar {
  progress: IStageProgress | undefined;
  width?: number;
  backgroundColor?: string;
  borderColor?: string;
}

const JourneyProgressBar: FunctionComponent<IJourneyProgressBar> = (props) => {
  const { progress, backgroundColor, borderColor } = props;
  const { todo, inProgress, finished } = progress || {
    ...INITIAL_STAGE_PROGRESS,
  };
  const numGoals = todo + inProgress + finished;
  const width = props.width ?? BASE_WIDTH;
  const goalWidth = width / numGoals;
  const classes = useStyles({ width, backgroundColor, borderColor });

  return (
    <div className={classes.root}>
      {finished > 0 && (
        <div
          className={classes.finished}
          style={{ width: goalWidth * finished + 4 }}
        />
      )}
      {inProgress > 0 && (
        <div
          className={classes.inProgress}
          style={{
            width: goalWidth * inProgress,
            marginLeft: inProgress !== numGoals ? '-4px' : '0',
          }}
        />
      )}
    </div>
  );
};

export default JourneyProgressBar;
