import React, { FunctionComponent } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IRichTextEditor {
  value: any;
  onChange: (value: any) => any;
  readonly?: boolean;
  width?: number;
  height?: number;
  onBlur?: (value: any) => any;
  widthStyle?: string;
}

const RichTextEditor: FunctionComponent<IRichTextEditor> = (props) => {
  const { value, onChange, readonly, width, height, onBlur, widthStyle } =
    props;

  return (
    <ReactQuill
      modules={{
        clipboard: {
          matchVisual: false,
        },
      }}
      theme='snow'
      value={value}
      onChange={onChange}
      readOnly={readonly}
      onBlur={onBlur}
      style={{
        width: widthStyle ? `${width}%` : `${width}px`,
        height: `${height}px`,
      }}
    />
  );
};

export default RichTextEditor;
