import React, { ChangeEvent, FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Theme, Typography, useTheme } from '@mui/material';
import { useJourneyTemplateContext } from './JourneyTemplateContext';
import DeleteIcon from '@mui/icons-material/Delete';
import JourneyTemplateMilestone from './JourneyTemplateMilestone';
import InputField from 'components/common/InputField';
import { Add, DensityMedium } from '@mui/icons-material';
import { IJourneyTheme } from 'utils/models';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: '#F5F5F5',
    display: 'flex',
    padding: '1.5rem 1rem',
    minHeight: '85px',
    border: '1px solid #E0E0E0',
  },
  name: {
    height: '2.5rem',
    borderRadius: '5px',
    position: 'absolute',
    left: '25px',
    top: '-15px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0.65rem',
    paddingRight: '0.25rem',

    '& h6': {
      fontSize: '15px',
      color: 'white',
    },
  },
  delete: {
    padding: '3px !important',
    borderRadius: '50% !important',
    zIndex: 100,

    '& svg': {
      color: 'white !important',
      opacity: '0.7 !important',
      transition: 'all 0.2s ease-in-out',
    },

    '&:hover': {
      '& svg': {
        opacity: '1 !important',
      },
    },
  },
  milestones: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
    marginTop: '1rem',
    width: '100%',
    marginRight: '2.25rem',
  },
  addMilestone: {
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    height: '55px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '0.25rem',
    paddingLeft: '1rem',

    '& svg': {
      height: '18px',
      width: '18px',
      color: theme.palette.primary.dark,
    },

    '& h6': {
      fontSize: '16px !important',
      marginTop: '3px !important',
    },
  },
}));

interface IJourneyTemplateTheme {
  index: number;
  theme: IJourneyTheme;
}

const JourneyTemplateTheme: FunctionComponent<IJourneyTemplateTheme> = (
  props,
) => {
  const { index, theme } = props;
  const { name, milestones } = theme;
  const {
    addStageMilestone,
    deleteStageTheme,
    updateStageThemeName,
    setStage,
    stage,
  } = useJourneyTemplateContext();
  const classes = useStyles();
  const appTheme = useTheme();

  function onDragEnd(result: DropResult) {
    if (!result.destination || stage === null) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const updatedMilestones = [...milestones];

    const [draggedMilestone] = updatedMilestones.splice(sourceIndex, 1);

    updatedMilestones.splice(destinationIndex, 0, draggedMilestone);

    const updatedTheme = { ...theme, milestones: updatedMilestones };

    const updatedThemes = [...stage.themes];
    updatedThemes[index] = updatedTheme;

    setStage({ ...stage, themes: updatedThemes });
  }

  function renderMilestones() {
    return milestones.map((milestone, i) => (
      <JourneyTemplateMilestone
        key={`theme-${index}-milestone-${i}`}
        index={i}
        themeIndex={index}
        milestone={milestone}
      />
    ));
  }

  return (
    <Draggable draggableId={`theme-${index}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <div className={classes.root}>
            <div className={classes.name}>
              <DensityMedium
                sx={{ color: appTheme.palette.primary.dark, marginRight: 2 }}
              />
              <div style={{ minWidth: '17.5rem' }}>
                <InputField
                  value={name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    updateStageThemeName(event.target.value, index)
                  }
                  placeholder={'Theme name...'}
                  sx={{ marginTop: '4px' }}
                />
              </div>
              <ButtonBase
                className={classes.delete}
                onClick={() => deleteStageTheme(index)}>
                <DeleteIcon />
              </ButtonBase>
            </div>
            <div className={classes.milestones}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='milestones'>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={classes.milestones}>
                      {renderMilestones()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div
                className={classes.addMilestone}
                onClick={() => addStageMilestone(index)}>
                <Add />
                <Typography variant={'h6'}>Add goal</Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default JourneyTemplateTheme;
