import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { ICompanyField } from 'utils/models';
import CompanySection from './CompanySection';
import CompanyField from './CompanyField';
import { isEmptyRichText } from 'utils/utils';

export interface ICompanySideSection {
  sidebar: ICompanyField[];
}

const CompanySideSection: FunctionComponent<ICompanySideSection> = (props) => {
  const { sidebar } = props;
  const { renderCompanyField } = CompanyField();

  function renderSidebar() {
    return sidebar.map((field, index) => {
      const companyField = renderCompanyField(field, index);
      const noData =
        (field.type === 'TEXT' && !field.text) ||
        (field.type === 'TAGS' && field.selectedTags === undefined) ||
        (field.type === 'RICH_TEXT' && isEmptyRichText(field.richText)) ||
        (field.type === 'FILES' && (!field.files || !field.files?.length)) ||
        (field.type === 'PEOPLE' && !field.people);

      return (
        <Box sx={{ marginBottom: '1rem' }} key={'sidebar-' + index}>
          {noData ? null : (
            <>
              {companyField && (
                <CompanySection title={field.name}>
                  {companyField}
                </CompanySection>
              )}
            </>
          )}
        </Box>
      );
    });
  }

  return <Box>{renderSidebar()}</Box>;
};

export default CompanySideSection;
