import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container, Fade } from '@mui/material';
import {
  GridOn,
  CalendarMonth,
  Add,
  Event,
  // EventAvailable,
  // InventoryRounded,
  SubdirectoryArrowRight,
} from '@mui/icons-material';
import AddEvent from 'components/Events/AddEvent';
import GridView from 'components/Events/GridView/GridView';
import CalendarView from 'components/Events/CalendarView/CalendarView';
import { getEcosystemInfo } from 'utils/storage';
import { useMobileContext } from 'services/contexts/MobileContext';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import EventDetails from 'components/Events/EventDetails';
import EditEvent from 'components/Events/EditEvent';
import CommonButton from 'components/common/CommonButton';
import ToggleViewButton from 'components/Events/ToggleViewButton';
import EventsSideMenu, {
  EventsMenuKeys,
} from 'components/Events/EventsSideMenu';
import { ISideMenuSection } from 'utils/models';
import { useParams } from 'react-router';
import { historyNavigate, trackUmamiEvent } from 'utils/utils';
import { Box } from '@mui/system';
import ConnectCalendarPopup from 'components/Booking/ConnectCalendarPopup';
import SearchFilter from 'components/common/SearchFilter';
import { FlexBox } from 'utils/styledComponents';

interface IEventsProps {
  defaultMenuOption?: EventsMenuKeys;
}

const Events: FunctionComponent<IEventsProps> = (props) => {
  const { defaultMenuOption } = props;
  const { isMobile, setMenuSections, setIsMenuVisible, setSelectedMenuOption } =
    useMobileContext();
  const { ecosystemName } = useParams();

  const {
    events,
    isLoading,
    setFilteredEvents,
    isShowAddEvent,
    setIsShowAddEvent,
    isShowEventDetails,
    chosenEvent,
    callCloseEventDetails,
    callCloseEditEvent,
    isShowEdit,
  } = useEventsContext();

  const [isCalendarView, setIsCalendarView] = useState<boolean>(false);

  const [isTriggerPopup, setIsTriggerPopup] = useState<boolean>(false);

  const getDefaultMenuOption = () => {
    if (defaultMenuOption) {
      return defaultMenuOption;
    } else {
      return EventsMenuKeys.Events;
    }
  };

  const [selectedOption, setSelectedOption] = useState<EventsMenuKeys>(
    getDefaultMenuOption(),
  );

  useEffect(() => {
    if (!isTriggerPopup) return;

    setIsTriggerPopup(false);
  }, [isTriggerPopup]);

  useEffect(() => {
    if (!isMobile) return;

    setIsMenuVisible(true);
    setMenuSections(sections);
    setSelectedMenuOption(selectedOption);
  }, [isMobile]);

  const sections: ISideMenuSection[] = [
    {
      key: EventsMenuKeys.Events,
      text: 'All events',
      icon: <Event />,
      onClick: () => {
        setMenuAndMobileChoice(EventsMenuKeys.Events);
      },
      hasAccess: true,
    },
    // {
    //   key: EventsMenuKeys.MyEvents,
    //   text: 'My events',
    //   icon: <EventAvailable />,
    //   onClick: () => {
    //     setMenuAndMobileChoice(EventsMenuKeys.MyEvents);
    //   },
    //   hasAccess: true,
    // },
    // {
    //   key: EventsMenuKeys.Forms,
    //   text: 'Forms',
    //   icon: <InventoryRounded />,
    //   onClick: () => {
    //     setMenuAndMobileChoice(EventsMenuKeys.Forms);
    //   },
    //   hasAccess: true,
    // },
    {
      key: EventsMenuKeys.Connect,
      text: 'Connect to calendar',
      icon: <SubdirectoryArrowRight />,
      onClick: () => setIsTriggerPopup(true),
      hasAccess: true,
      isSpecial: true,
    },
  ];

  function setMenuOption(menuKey: EventsMenuKeys) {
    switch (menuKey) {
      case EventsMenuKeys.Events:
        setSelectedOption(EventsMenuKeys.Events);
        historyNavigate(`/ecosystem/${ecosystemName}/events`);
        break;
      case EventsMenuKeys.MyEvents:
        setSelectedOption(EventsMenuKeys.MyEvents);
        historyNavigate(`/ecosystem/${ecosystemName}/events/my`);
        break;
      case EventsMenuKeys.Forms:
        setSelectedOption(EventsMenuKeys.Forms);
        historyNavigate(`/ecosystem/${ecosystemName}/events/forms`);
        break;
      default:
        break;
    }
  }

  function setMenuAndMobileChoice(key: EventsMenuKeys) {
    setMenuOption(key);

    if (!isMobile) return;

    setSelectedMenuOption(key);
  }

  function renderViewChoice() {
    return (
      <FlexBox
        className={'between'}
        sx={{
          marginBottom: '1.875rem',
        }}>
        <FlexBox
          className={'center'}
          sx={{
            gap: '1.25rem',
            justifyContent: { xs: 'space-evenly', sm: 'start' },
            minWidth: { xs: '100%', sm: '30%' },
          }}>
          <ToggleViewButton
            onClick={() => setIsCalendarView(false)}
            text={'Grid View'}
            isActive={!isCalendarView}
            icon={<GridOn />}
          />
          <ToggleViewButton
            onClick={() => {
              setIsCalendarView(true);
              trackUmamiEvent('Calendar View');
            }}
            text={'Calendar'}
            isActive={isCalendarView}
            icon={<CalendarMonth />}
          />
        </FlexBox>

        <FlexBox
          className={'end'}
          sx={{
            gap: '1.25rem',
            display: { xs: 'none', sm: 'flex' },
          }}>
          {!isLoading && events && (
            <Box
              sx={{
                minWidth: { sm: '15rem', lg: '31.25rem' },
                maxWidth: { sm: '15rem', md: '20rem', lg: '31.25rem' },
              }}>
              <SearchFilter
                returnDirectly
                collection={events}
                filterOnField={['title', 'description', 'tagsString']}
                onFilter={setFilteredEvents}
              />
            </Box>
          )}
          {renderAddButton()}
        </FlexBox>
      </FlexBox>
    );
  }

  function renderAddButton() {
    return (
      <>
        {getEcosystemInfo().accessLevel.canCreateEvents && (
          <CommonButton
            onClick={() => {
              setIsShowAddEvent(true);
            }}
            icon={<Add />}
            text={'Create event'}
            sx={{
              marginLeft: '0.625rem',
              minWidth: '10rem',
              maxHeight: '2.5rem',
            }}
          />
        )}
        {isShowAddEvent && (
          <AddEvent
            isOpen={isShowAddEvent}
            handleClose={() => {
              setIsShowAddEvent(false);
            }}
          />
        )}
      </>
    );
  }

  function renderSection() {
    if (selectedOption === EventsMenuKeys.Events) {
      return <Events />; // This is not the correct Events page, create grid/calendar view of events
    }

    if (selectedOption === EventsMenuKeys.MyEvents) {
      return <Events />; // This is not the correct page
    }

    if (selectedOption === EventsMenuKeys.Forms) {
      return <Events />; // This is not the correct page
    }

    return <Events />;
  }

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 5rem)',
          marginTop: '-3rem',
        }}>
        <ConnectCalendarPopup isTriggerPopup={isTriggerPopup} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            width: '100%',
          }}>
          <Box>
            {!isMobile && (
              <EventsSideMenu
                menuItems={sections}
                selectedOption={selectedOption}
              />
            )}
          </Box>
          <Container
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'start',
              marginX: 'auto',
              marginTop: '3rem',
              maxWidth: { xs: '100%', md: '74vw', lg: '78vw', xl: '110rem' },
              marginBottom: '6rem',
            }}>
            <Box>
              {renderViewChoice()}
              {!isCalendarView ? <GridView /> : <CalendarView />}
            </Box>
            {!!chosenEvent && (
              <EventDetails
                isOpen={isShowEventDetails}
                handleClose={callCloseEventDetails}
                event={chosenEvent}
              />
            )}
            {isShowEdit && !!chosenEvent && (
              <EditEvent
                isOpen={isShowEdit}
                handleClose={callCloseEditEvent}
                eventToEdit={chosenEvent}
              />
            )}
          </Container>
        </Box>
      </Box>
    </Fade>
  );
};

export default Events;
