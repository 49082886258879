import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CompanyProfileFieldType,
  ICompanyField,
  ICompanyProfileInfo,
  ITag,
} from 'utils/models';
import InputField from '../common/InputField';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import TagsField from '../common/Tags/TagsField';
import { naturalPallete, primary } from 'theme';
import { FlexBox } from 'utils/styledComponents';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import { IconPicker } from 'components/common/IconPicker';
import { useParams } from 'react-router';
import UploadImage from 'components/common/UploadImage';
import { MoreVert } from '@mui/icons-material';
import CompanyContact from './CompanyContact';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InformationModal from 'components/common/Modal/InformationModal';
import CompanyEditSocialMediaAndContacts from './CompanyEditSocialMediaAndContacts';
import {
  COMPANY_LOGO_ROUTE,
  deleteData,
  putData,
  COMPANY_BANNER,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';
interface ICompanyEditHeader {
  companyName: string;
  canDeleteCompanyProfile: boolean;
  description: string;
}

const CompanyEditHeader: FunctionComponent<ICompanyEditHeader> = (props) => {
  const {
    companyProfile,
    updateCompanyLogo,
    setEditedName,
    editedName,
    setEditedLogo,
    updateCompanyField,
    updateCompanyDescription,
    setCompanyProfile,
    removeSelectedTag,
    checkIfInfoIsMissing,
    updateCompanyBanner,
  } = useCompanyContext();

  const { addAlert } = useContext(AlertContext);

  const { companyName, canDeleteCompanyProfile } = props;
  const { ecosystemName } = useParams();
  const theme = useTheme();
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLogoLoading, setIsLogoLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const uploadRef = useRef<{ triggerUpload: () => void } | null>(null);

  const companyDescription = companyProfile!.company.description;

  const [copyCompanyProfiles, setCopyCompanyProfiles] =
    useState<ICompanyProfileInfo>(
      JSON.parse(JSON.stringify(companyProfile!.company)),
    );

  async function uploadNewCompanyBanner(file: any) {
    setIsLoading(true);

    try {
      const formData = new FormData();

      formData.append('companyBanner', file);

      const data = await putData(
        COMPANY_BANNER,
        [
          { name: 'ecosystemName', value: ecosystemName },
          {
            name: 'companyName',
            value: companyProfile!.company.name,
          },
        ],
        formData,
      );

      updateCompanyBanner(data);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Banner updated',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function deleteLogo() {
    setIsLogoLoading(true);

    try {
      const data = await deleteData(COMPANY_LOGO_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyName', value: companyName },
      ]);

      updateCompanyLogo(data);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Logo Deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLogoLoading(false);
  }

  async function deleteBanner() {
    setIsLoading(true);

    try {
      const data = await deleteData(COMPANY_BANNER, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyName', value: companyName },
      ]);

      updateCompanyBanner(data);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Banner Deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderCompanyNameEditor() {
    return (
      <>
        <InputField
          value={editedName || companyProfile!.company.name}
          placeholder={'Acterio'}
          readonly={isLoading}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setEditedName(event.target.value)
          }
        />
      </>
    );
  }

  function renderTagsEditor() {
    return companyProfile!.header.map((field: ICompanyField) => {
      if (field.type !== CompanyProfileFieldType.TAGS) {
        return null;
      }
      checkIfInfoIsMissing(field, 'selectedTags');

      const { tagOptions = [], selectedTags = [], id } = field;

      return (
        <Box key={'tags-editor-' + id}>
          <FlexBox
            sx={{
              width: '100%',
              height: '2rem',
              mb: '1rem',
              whiteSpace: 'wrap',
            }}>
            <TagsField
              tags={selectedTags}
              tagOptions={tagOptions}
              selectedTags={selectedTags}
              updateCompanyField={(id: string, newTags: ITag[]) =>
                updateCompanyField(Number(id), newTags)
              }
              id={id.toString()}
              updateTags={(tagName: string) => {
                const tagToRemove = selectedTags.find(
                  (tag: ITag) => tag.name === tagName,
                );
                if (tagToRemove) {
                  removeSelectedTag(id, tagToRemove);
                }
              }}
              sx={{
                border:
                  selectedTags.length < 1
                    ? `1px solid ${primary.warningRed}`
                    : `1px solid ${primary.natural6}`,
              }}
            />
          </FlexBox>
        </Box>
      );
    });
  }

  function renderCardMedia() {
    return (
      <Box
        sx={{
          position: 'relative',
          height: '15rem',
          display: 'flex',
        }}>
        <CardMedia
          component='img'
          src={companyProfile!.company.bannerUrl}
          alt='CompanyCard-Background'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            position: 'relative',
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {!isLogoLoading ? (
            <UploadImage
              onUpload={(file) => setEditedLogo(file)}
              initialSrc={companyProfile!.logoUrl}
              uploadText={'Upload Logo'}
              showButtons={false}
              isDisabled={isLoading}
              borderColor={naturalPallete.natural6}
              crop={true}
              aspectRatioHeight={1}
              aspectRatioWidth={2}
              deleteFunction={deleteLogo}
              sx={{
                width: '20rem',
                maxWidth: '20rem',
                maxHeight: '10rem',
                marginTop: '1.75rem',
              }}
              cameraIconOffset={'-7rem'}
              isLoading={isLogoLoading}
            />
          ) : (
            <Loading />
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '-1rem',
            right: '1rem',
            padding: '1rem',
          }}>
          <UploadImage
            onUpload={(file) => uploadNewCompanyBanner(file)}
            initialSrc={companyProfile!.company.bannerUrl}
            uploadText={'Upload banner'}
            showButtons={false}
            isDisabled={isLoading}
            borderColor={naturalPallete.natural6}
            crop={true}
            aspectRatioHeight={1}
            aspectRatioWidth={4}
            deleteFunction={deleteBanner}
            sx={{
              width: '0.5rem',
              maxWidth: '0.5rem',
              maxHeight: '0.5rem',
            }}
            cameraIconOffset={'0rem'}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    );
  }

  function renderCompanyTemplateTag() {
    return (
      <AccessLevelTagCard
        accessLevelName={companyProfile?.companyTemplateName || 'Unknown'}
        accessLevelColor={companyProfile?.color || undefined}
        left={'1rem'}
        height={'2rem'}
        sx={{ display: 'inline-flex', whiteSpace: 'nowrap', mr: '1.5rem' }}
      />
    );
  }

  function renderHeaderContainer() {
    return (
      <>
        <FlexBox
          sx={{
            justifyContent: 'space-between',
            alignItems: 'start',
            flexWrap: 'noWrap',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              flexWrap: 'wrap',
              mb: '0.3rem',
            }}>
            <Typography variant={'h5'} sx={{ mr: '1rem', mb: '1.3rem' }}>
              {renderCompanyNameEditor()}
            </Typography>
            {renderTagsEditor()}
          </Box>
          <FlexBox>
            {renderCompanyTemplateTag()}
            <FlexBox
              sx={{ color: naturalPallete.primaryDisabledFont, mr: '1.2rem' }}>
              <MoreVert />
            </FlexBox>
          </FlexBox>
        </FlexBox>
        {/* {renderDeleteModal()} */}
      </>
    );
  }

  function renderHeaderFieldsEditor() {
    return companyProfile!.header.map((field: ICompanyField) => {
      if (field.type !== CompanyProfileFieldType.TEXT) {
        return null;
      }
      checkIfInfoIsMissing(field, 'text');
      return (
        <FlexBox key={'text-editor-' + field.id} sx={{ columnGap: '0.5rem' }}>
          <Tooltip title={field.name}>
            <Box>
              <IconPicker
                name={field.icon}
                sx={{ color: theme.palette.primary.main }}
              />
            </Box>
          </Tooltip>

          <Box>
            <InputField
              error={field.required && !field.text}
              value={field.text || ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                updateCompanyField(field.id, event.target.value)
              }
              placeholder={field.name}
            />
          </Box>
        </FlexBox>
      );
    });
  }

  function revertChanges() {
    if (!companyProfile) return;
    setCompanyProfile({ ...companyProfile, company: copyCompanyProfiles });
  }

  function renderDescriptionEditor() {
    return (
      <InputField
        value={companyProfile?.company.description || ''}
        placeholder='Description'
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          updateCompanyDescription(event.target.value)
        }
        sx={{
          width: '100%',
          mb: '1rem',
        }}
      />
    );
  }

  function renderContactsEditor() {
    return (
      <>
        <FlexBox
          sx={{
            maxWidth: '15rem',
            mx: '1rem',
            pl: '1rem',
            pr: '0.5rem',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${naturalPallete.natural6}`,
            borderRadius: '8px',
          }}>
          <PhoneIcon sx={{ mr: '0.5rem' }} />
          <EmailIcon />
          <CompanyContact deactivate={true} sx={{ alignItems: 'center' }} />
          <FlexBox
            onClick={() => setIsModalOpen(true)}
            sx={{
              justifyContent: 'center',
              alighItems: 'center',
              height: '1.5rem',
              width: '1.5rem',
              p: '1rem',

              borderRadius: '50%',
              color: naturalPallete.mainBlackFont,
              cursor: 'pointer',
              ':hover': {
                backgroundColor: theme.palette.primary.light,
              },
            }}>
            <MoreVert />
          </FlexBox>
        </FlexBox>
        <InformationModal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          primaryText='Save'
          primaryOnClick={() => setIsModalOpen(false)}
          secondaryText='Revert changes'
          secondaryOnClick={revertChanges}
          headerText={'Social media links & contact details'}>
          <Box sx={{ mt: '1rem' }}>
            <CompanyEditSocialMediaAndContacts companyName={companyName} />
          </Box>
        </InformationModal>
      </>
    );
  }

  return (
    <>
      <Card
        sx={{
          width: '100%',
          mb: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: '12px',
          backgroundColor: `${primary.pureWhite}`,
          border: `1px solid ${primary.natural6}`,
        }}>
        {renderCardMedia()}
        <CardContent sx={{ pr: '0rem' }}>
          {renderHeaderContainer()}
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignItems: 'end',
            }}>
            <Grid item xs={12} sx={{ mr: '1rem' }}>
              {renderDescriptionEditor()}
            </Grid>

            <Grid item xs={9}>
              <FlexBox
                sx={{
                  flexWrap: 'wrap-reverse',
                  columnGap: '0.5rem',
                  rowGap: '0.5rem',
                }}>
                {renderHeaderFieldsEditor()}
              </FlexBox>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {renderContactsEditor()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div>{/* {renderDateEditor()} */}</div>
    </>
  );
};

export default CompanyEditHeader;
