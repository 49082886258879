import { Button, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { primary } from 'theme';
import {
  Close,
  Check,
  Place,
  Send,
  Forward,
  ArrowDropDownCircleOutlined,
  AccessTime,
} from '@mui/icons-material';
import InputField from 'components/common/InputField';
import {
  MEETING_CANCEL_USER,
  MEETING_HANDLE_REQUEST,
  deleteData,
  putData,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';
import { formatDistanceToNow } from 'date-fns';
import { IRequest } from 'utils/models';

interface RequestProps {
  request: IRequest;
  isMyRequests: boolean;
  setRequests: (requests: any) => void;
}

const Request: FunctionComponent<RequestProps> = (props) => {
  const { request, isMyRequests, setRequests } = props;

  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isAccept, setIsAccept] = useState<boolean>(true);
  const [optionalMessage, setOptionalMessage] = useState<string>('');
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const timeDifferance = formatDistanceToNow(new Date(request.requestTime), {
    addSuffix: true,
  });

  function viewProfile() {
    navigate(
      `/ecosystem/${ecosystemName}/members/${
        isMyRequests ? request.bookedUser.id : request.bookingUser.id
      }`,
    );
  }

  function handleAccept() {
    setIsConfirm(true);
    setIsAccept(true);
  }

  function handleReject() {
    setIsConfirm(true);
    setIsAccept(false);
  }

  async function handleSend() {
    setIsLoading(true);

    try {
      await putData(MEETING_HANDLE_REQUEST, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'meetingId', value: request.id },
        { name: 'status', value: isAccept },
        {
          name: 'message',
          value: optionalMessage,
        },
      ]);

      setRequests((prevRequests: IRequest[]) =>
        prevRequests.filter((req: IRequest) => req.id !== request.id),
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Meeting ${isAccept ? 'accepted' : 'rejected'}`,
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function handleCancel() {
    setIsLoading(true);

    try {
      await deleteData(MEETING_CANCEL_USER, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'meetingId', value: request.id },
        {
          name: 'message',
          value: optionalMessage,
        },
      ]);

      setRequests((prevRequests: IRequest[]) =>
        prevRequests.filter((req: IRequest) => req.id !== request.id),
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Meeting canceled',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderPhoto() {
    return (
      <Box
        sx={{
          height: '100%',
          width: '6.25rem',
          maxHeight: 100,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            borderRadius: 0.2,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
          onClick={viewProfile}>
          <img
            src={
              'https://storage.googleapis.com/enablemagic.appspot.com/deployed/company-cover-images/default.png'
            }
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            marginLeft: '6.25rem',
            border: 1,
            borderRadius: 30,
            width: 60,
            height: 60,
            overflow: 'hidden',
            cursor: 'pointer',
          }}
          onClick={viewProfile}>
          <img
            src={
              request.bookedUser.profilePic ??
              'https://storage.googleapis.com/enablemagic.appspot.com/deployed/company-cover-images/default.png'
            }
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Box>
    );
  }

  function renderMeetingDetails() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: { xs: '1rem', md: '2rem', lg: '0rem' },
          alignItems: 'left',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}>
        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontWeight: 'bold',
            fontSize: '1rem',
          }}>
          {request.topic || 'Default Name'}
        </Typography>
        <Typography fontSize={'0.875rem'} color={theme.palette.primary.main}>
          {`with ${
            isMyRequests
              ? request.bookedUser.fullName.split(' ')[0]
              : request.bookingUser.fullName.split(' ')[0]
          }`}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTime
            sx={{
              color: theme.palette.primary.main,
              fontSize: '1.2rem',
              marginRight: '0.5rem',
            }}
          />
          <Box
            sx={{
              bgcolor: theme.palette.primary.light,
              paddingX: 1,
              paddingY: 0.2,
              borderRadius: '6px',
              marginLeft: -0.5,
              marginRight: 1,
              width: 108,
              maxHeight: 25,
            }}>
            <Typography
              fontSize={'0.875rem'}
              fontWeight='bold'
              color={theme.palette.primary.main}>{`${request.startTime} - ${
              request.endTime || '19:30'
            }`}</Typography>
          </Box>
          {request.platform && (
            <Box
              sx={{
                display: 'flex',
                marginTop: 0.2,
                maxWidth: 100,
                overflow: 'hidden',
              }}>
              <Place
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '0.875rem',
                  marginRight: 0.5,
                  marginTop: 0.2,
                }}
              />
              <Typography
                fontSize={'0.875rem'}
                fontWeight='bold'
                color={theme.palette.primary.main}
                noWrap>
                {request.platform}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  function renderRequestDetails() {
    const isExceedingLength = request.info.length > 64;
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box marginRight={'1rem'} marginTop={'0.2rem'}>
          <Box
            sx={{
              borderRadius: 25,
              width: '3.2rem',
              height: '3.2rem',
              overflow: 'hidden',
              cursor: 'pointer',
            }}
            onClick={viewProfile}>
            <img
              src={request.bookingUser.profilePic}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
          <Box
            sx={{
              position: 'relative',
              top: -10,
              left: 0,
              borderRadius: '6px',
              bgcolor: '#FAF8BC',
              padding: 0.5,
              width: '3.2rem',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Typography fontSize={'0.6rem'} fontWeight='bold'>
              {isMyRequests
                ? request.bookedUser.accessLevelName
                : request.bookingUser.accessLevelName}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography
            fontWeight='bold'
            fontSize={'0.875rem'}
            gutterBottom
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {isMyRequests
              ? request.bookedUser.fullName
              : request.bookingUser.fullName}
          </Typography>
          <Box
            sx={{
              height: isShowMore ? 112 : 56,
              overflow: isShowMore ? 'inherit' : 'hidden',
              bgcolor: '#FAFAFA',
              borderRadius: '6px',
              padding: 0.5,
              marginLeft: -0.5,
              marginTop: -0.5,
            }}>
            <Typography fontSize={'0.75rem'} textOverflow='ellipsis'>
              {request.info ||
                'The user did not provide additional information'}
            </Typography>
          </Box>
        </Box>
        <Button
          sx={{
            minWidth: 'unset',
            width: 10,
            height: 10,
            borderRadius: 5,
            position: 'relative',
            right: 0,
            top: 0,
            // marginRight: -1,
            // marginLeft: 1,
          }}
          disabled={!isExceedingLength}
          onClick={() => setIsShowMore((prev) => !prev)}>
          <ArrowDropDownCircleOutlined
            sx={{
              color: isExceedingLength ? '#969696' : '#FFFFFF',
              transform: isShowMore ? 'rotate(180deg)' : 'inherit',
            }}
          />
        </Button>
      </Box>
    );
  }

  function renderButtons() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {!isMyRequests && (
            <Button
              onClick={handleAccept}
              sx={{
                bgcolor: '#DDFFF2',
                border: '1px solid #9FD6D4',
                width: 40,
                height: 40,
                borderRadius: '100%',
                minWidth: 'unset',
                '&:hover': {
                  backgroundColor: '#9FD6D4',
                },
              }}>
              <Check sx={{ color: '#05BCA0' }} />
            </Button>
          )}
          <Button
            onClick={handleReject}
            sx={{
              bgcolor: '#FFE7E6',
              border: '1px solid #DB9F9D',
              margin: 1,
              width: 40,
              height: 40,
              borderRadius: '100%',
              minWidth: 'unset',
              '&:hover': {
                backgroundColor: '#DB9F9D',
              },
            }}>
            <Close sx={{ color: '#D25B58' }} />
          </Button>
        </Box>
        <Typography
          variant='body2'
          sx={{
            textAlign: 'center',
          }}
          marginX={1}>{`${
          isMyRequests ? 'Requested' : 'Received'
        } ${timeDifferance}`}</Typography>
      </Box>
    );
  }

  function renderConfirmForm() {
    return (
      <Grid
        container
        sx={{
          height: '100%',
          width: '100%',
          bgcolor: isAccept ? '#EAFFF7' : '#FFE7E6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: '1rem',
        }}>
        <Grid
          item
          xs={3}
          sm={4}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingRight: '1rem',
          }}>
          <Typography
            fontSize={'1rem'}
            fontWeight='bold'
            color={isAccept ? '#9FD6D4' : '#D25B58'}
            marginRight={'1rem'}>
            {isAccept
              ? 'Do you wish to accept the meeting?'
              : 'Do you want to cancel the meeting?'}
          </Typography>
          {isAccept ? (
            <Check sx={{ color: '#05BCA0', fontSize: '2rem' }} />
          ) : (
            <Close sx={{ color: '#D25B58', fontSize: '2rem' }} />
          )}
        </Grid>

        <Grid
          item
          xs={5}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}>
          <Box sx={{ width: '100%', paddingRight: '1rem' }}>
            <InputField
              value={optionalMessage}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setOptionalMessage(event.target.value)
              }
              placeholder={`Optional message for ${
                isMyRequests
                  ? request.bookedUser.fullName
                  : request.bookingUser.fullName
              }...`}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={4}
          sm={3}
          md={2}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
          }}>
          <Button
            onClick={isMyRequests ? handleCancel : handleSend}
            sx={{
              bgcolor: theme.palette.primary.dark,
              minWidth: 'unset',
              width: '3.2rem',
              height: '3.2rem',
              borderRadius: '6px',
              marginLeft: 2,
              '&:hover': { bgcolor: theme.palette.primary.contrastText },
            }}>
            <Tooltip title='Confirm and send'>
              <Send
                sx={{
                  fontSize: 28,
                  color: primary.pureWhite,
                  transform: 'rotate(-45deg)',
                  marginLeft: 0.5,
                  marginBottom: 0.5,
                }}
              />
            </Tooltip>
          </Button>
          <Button
            sx={{
              bgcolor: theme.palette.primary.dark,
              minWidth: 'unset',
              width: '3.2rem',
              height: '3.2rem',
              borderRadius: '6px',
              marginLeft: 2,
              '&:hover': { bgcolor: theme.palette.primary.contrastText },
            }}
            onClick={() => setIsConfirm(false)}>
            <Tooltip title='Go back'>
              <Forward
                sx={{
                  fontSize: 28,
                  color: primary.pureWhite,
                  transform: 'rotate(180deg)',
                }}
              />
            </Tooltip>
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', md: 100 },
        bgcolor: '#FFFFFF',
        border: 1,
        borderColor: '#D4D4D4',
        borderRadius: '6px',
        marginY: 2,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      {isLoading ? (
        <Loading marginTop={-150} />
      ) : isConfirm ? (
        <>{renderConfirmForm()}</>
      ) : (
        <Grid container width={'100%'}>
          <Grid
            item
            xs={4}
            md={2}
            sx={{
              borderBottom: { xs: '1px solid #D4D4D4', md: 'transparent' },
            }}>
            {renderPhoto()}
          </Grid>
          <Grid
            item
            xs={8}
            md={3}
            height={{ xs: 'unset', md: '100%' }}
            sx={{
              borderRight: { xs: '', md: '1px solid #D4D4D4' },
              borderBottom: { xs: '1px solid #D4D4D4', md: 'transparent' },
              padding: { xs: '1rem', md: 0 },
            }}>
            {renderMeetingDetails()}
          </Grid>
          <Grid
            item
            xs={8}
            md={4}
            sx={{
              borderRight: { xs: '', md: '1px solid #D4D4D4' },
              padding: { xs: '1rem', md: 0 },
              paddingLeft: { xs: '1rem', md: '0.5rem' },
            }}>
            {renderRequestDetails()}
          </Grid>
          <Grid item xs={4} md={3}>
            {renderButtons()}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Request;
