import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Typography, useTheme } from '@mui/material';
import { IJourneyTheme } from 'utils/models';
import JourneyMilestone from './JourneyMilestone';
import { Box } from '@mui/system';
import { useJourneyContext } from './JourneyContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  name: {
    borderRadius: '6px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '0.5rem 1.5rem',
    width: '8.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cont: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface IJourneyThemeProps {
  theme: IJourneyTheme;
  themeIndex: number;
}

const JourneyTheme: FunctionComponent<IJourneyThemeProps> = (props) => {
  const { theme, themeIndex } = props;
  const { name, milestones, customMilestones } = theme;
  const classes = useStyles();

  const appTheme = useTheme();

  const { isEditJourneyContext, addCustomMilestone } = useJourneyContext();

  function renderMilestones() {
    return milestones.map((milestone, i) => (
      <JourneyMilestone
        key={milestone.id}
        milestone={milestone}
        isFirst={i === 0}
        isLast={i === milestones.length - 1}
        milestoneIndex={i}
        themeIndex={themeIndex}
      />
    ));
  }

  function renderCustomMilestones() {
    if (customMilestones !== undefined) {
      return customMilestones.map((milestone, i) => (
        <JourneyMilestone
          key={`Milestone ${i}`}
          milestone={milestone}
          isFirst={i === 0}
          isLast={i === milestones.length - 1}
          milestoneIndex={i}
          themeIndex={themeIndex}
          isCustom={true}
        />
      ));
    }
  }

  function renderAddCustomMilestoneButton() {
    return (
      <div>
        <Box
          sx={{
            height: 40,
            display: 'flex',
            justifyContent: 'space-between',
            border: '2px dashed rgba(168,168,168,0.9)',
            borderTop:
              milestones.length || customMilestones?.length
                ? 'none'
                : '2px dashed rgba(168,168,168,0.9)',
            bgcolor: '#f7f2de',
          }}>
          <Button
            onClick={() => addCustomMilestone(themeIndex)}
            sx={{ justifyItems: 'center' }}>
            <Typography
              sx={{
                fontSize: 14,
                color: appTheme.palette.primary.dark,
                marginLeft: 1,
                fontWeight: 'bold',
              }}>
              + Add custom goal
            </Typography>
          </Button>
        </Box>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div style={{ width: 'fit-content' }} className={classes.name}>
        {name}
      </div>
      <div className={classes.cont}>
        {renderMilestones()}
        {renderCustomMilestones()}
      </div>
      {isEditJourneyContext && renderAddCustomMilestoneButton()}
    </div>
  );
};

export default JourneyTheme;
