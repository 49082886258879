import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ErrorOutline, Upload } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import GetFileIcon from 'components/common/GetFileIcon';
import Loading from 'components/common/Loading';
import CloseModalButton from 'components/common/Modal/CloseModalButton';
import InformationModal from 'components/common/Modal/InformationModal';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useParams } from 'react-router';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { colorPallete, naturalPallete } from 'theme';
import { ICompanyField, ICompanyProfileInfo, IUploadFile } from 'utils/models';
import { deleteData, postData } from 'utils/requests';
import { FlexBox } from 'utils/styledComponents';

interface IUploadSidesectionFiles {
  field: ICompanyField;
}

const CompanySideSectionUploadFiles: FunctionComponent<
  IUploadSidesectionFiles
> = (props) => {
  const { field } = props;

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const {
    companyProfile,
    updateCompanyField,
    setCompanyProfile,
    checkIfInfoIsMissing,
  } = useCompanyContext();

  const [files, setFiles] = useState<File[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [rejected, setRejected] = useState<FileRejection[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [copyCompanyProfiles, setCopyCompanyProfiles] =
    useState<ICompanyProfileInfo>(
      JSON.parse(JSON.stringify(companyProfile!.company)),
    );
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles?.length) {
        setFiles((previousFiles) => [
          ...previousFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) }),
          ),
        ]);
        setErrorMessage(null);
      }

      if (rejectedFiles?.length) {
        setRejected((previousFiles) => [...previousFiles, ...rejectedFiles]);
        const errorCode = rejectedFiles[0].errors[0].code;
        if (errorCode === 'file-invalid-type') {
          setErrorMessage('Invalid file format. File was not uploaded.');
        } else if (errorCode === 'file-too-large') {
          setErrorMessage('File size is too large. File was not uploaded.');
        }
      }
    },
    [],
  );

  const { getRootProps, getInputProps, isDragActive, isFileDialogActive } =
    useDropzone({
      onDrop,
      accept: {
        'text/plain': ['.txt'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          ['.docx'],
        'application/vnd.ms-powerpoint': ['.pp'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          ['.pptx'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx',
        ],
        'image/jpeg': ['.jpg'],
        'image/png': ['.png'],
        'image/gif': ['.gif'],
      },

      maxSize: 50 * 1000 * 1000,
    });

  const fieldFiles = field.files ? field.files : [];

  async function uploadFiles(fieldId: number) {
    setIsLoading(true);

    try {
      const formData = new FormData();

      let i = 1;
      for (const item of files) {
        formData.append(i.toString(), item);
        i++;
      }

      const data = await postData(
        '/company-profile/upload',
        [
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'companyProfileFieldId', value: fieldId },
          {
            name: 'companyName',
            value: companyProfile!.company.name,
          },
        ],
        formData,
      );

      updateCompanyField(fieldId, [...fieldFiles, ...data]);
      setIsModalOpen(false);
      setFiles([]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Uploaded successfully',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function saveUploadedFiles(field: ICompanyField) {
    uploadFiles(field.id);
  }

  const removeFile = (name: string) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };

  async function deleteFile(file: IUploadFile) {
    setIsDeleteLoading(true);

    try {
      const data = await deleteData('/company-profile/upload', [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyProfileFieldId', value: field.id },
        {
          name: 'companyName',
          value: companyProfile!.company.name,
        },
        {
          name: 'fileId',
          value: file.id,
        },
      ]);

      const updatedFiles = field.files?.filter(
        (f: IUploadFile) => f.name !== file.name,
      );

      updateCompanyField(field.id, updatedFiles);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Deleted successfully',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsDeleteLoading(false);
  }

  function revertChanges() {
    if (!companyProfile) return;
    setCompanyProfile({ ...companyProfile, company: copyCompanyProfiles });
    setFiles([]);
    setIsModalOpen(false);
  }

  const renderFileDisplay = (file: any, index: number) => (
    <FlexBox
      key={index}
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '1rem',
      }}>
      <FlexBox
        sx={{
          gap: '0.5rem',
        }}>
        <Box sx={{ img: { height: '1.7rem' } }}>
          <GetFileIcon file={file.name.split('.').pop()} />
        </Box>
        <Box
          sx={{
            a: {
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
                textDecorationColor: naturalPallete.natural4,
              },
            },
          }}>
          <a
            href={URL.createObjectURL(file)}
            target='_blank'
            rel='noopener noreferrer'>
            <Typography
              sx={{
                ':hover': {
                  color: naturalPallete.natural2,
                },
              }}>
              {file.name}
            </Typography>
          </a>
        </Box>
      </FlexBox>
      <CloseModalButton
        handleClose={() => removeFile(file.name)}
        color={naturalPallete.mainBlackFont}
        sx={{ boxShadow: 'none' }}
      />
    </FlexBox>
  );

  const renderIUploadFileDisplay = (file: IUploadFile, index: number) => (
    <FlexBox
      key={index}
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '1rem',
      }}>
      <FlexBox
        sx={{
          gap: '0.5rem',
        }}>
        <Box sx={{ img: { height: '1.7rem' } }}>
          <GetFileIcon file={file.name.split('.').pop()} />
        </Box>
        <Box
          sx={{
            a: {
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
                textDecorationColor: naturalPallete.natural4,
              },
            },
          }}>
          <a href={file.url} target='_blank' rel='noopener noreferrer'>
            <Typography
              sx={{
                ':hover': {
                  color: naturalPallete.natural2,
                },
              }}>
              {file.name}
            </Typography>
          </a>
        </Box>
      </FlexBox>
      <CloseModalButton
        handleClose={() => deleteFile(file)}
        color={naturalPallete.mainBlackFont}
        sx={{ boxShadow: 'none' }}
      />
    </FlexBox>
  );

  checkIfInfoIsMissing(field, 'files');
  return (
    <>
      <Box
        sx={{
          p: '1rem',
          border:
            field.required && fieldFiles.length < 1
              ? `1px solid ${colorPallete.warningRed}`
              : '',
          borderRadius: '6px',
        }}>
        {isDeleteLoading ? (
          <Loading height={40} />
        ) : (
          fieldFiles.map(renderIUploadFileDisplay)
        )}
        {field.required && fieldFiles.length < 1 && (
          <Typography
            variant='body1'
            color={colorPallete.warningRed}
            sx={{ pb: '1rem' }}>
            Upload {field.name}
          </Typography>
        )}
        <FlexBox sx={{ gap: '0.5rem', cursor: 'pointer' }} onClick={openModal}>
          <Upload />
          <Typography variant='h6'>Upload file</Typography>
        </FlexBox>
      </Box>
      <InformationModal
        isOpen={isModalOpen}
        handleClose={closeModal}
        primaryText='Save'
        primaryOnClick={() => saveUploadedFiles(field)}
        secondaryText='Cancel'
        secondaryOnClick={revertChanges}
        headerText={`Upload ${field.name}`}
        headerSize={'2rem'}
        width={'34rem'}>
        {isLoading ? (
          <Box>
            <Loading />
          </Box>
        ) : (
          <Box>
            <FlexBox
              {...getRootProps()}
              sx={{
                my: '1rem',
                maxWidth: '30rem',
                height: '10rem',
                border: errorMessage
                  ? `1px dashed ${colorPallete.warningRed}`
                  : isDragActive || isFileDialogActive
                  ? `1px dashed ${colorPallete.additionalDeepBlue}`
                  : `1px dashed ${naturalPallete.natural6}`,
                borderRadius: '12px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <>
                  <Typography>Drop the files here</Typography>
                </>
              ) : (
                <>
                  <Typography>
                    <b> Choose a file</b> or drag it here
                  </Typography>
                  <Typography sx={{ px: '1rem', textAlign: 'center' }}>
                    Supports PDF, TXT, PP, PPTX, DOC, DOCX, XSLX, JPG, GIF or
                    PNG
                  </Typography>
                  <Typography>(max size 50MB)</Typography>
                </>
              )}
            </FlexBox>
            {errorMessage && (
              <Typography sx={{ color: colorPallete.warningRed }}>
                <FlexBox sx={{ gap: '0.5rem' }}>
                  <ErrorOutline />
                  {errorMessage}
                </FlexBox>
              </Typography>
            )}
            {files.map(renderFileDisplay)}
          </Box>
        )}
      </InformationModal>
    </>
  );
};

export default CompanySideSectionUploadFiles;
