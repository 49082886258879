import React from 'react';
import { ICompanyField, IUploadFile } from 'utils/models';
import { Box, Typography, useTheme } from '@mui/material';
import RichTextDisplay from '../common/RichTextDisplay';
import PeopleDisplay from 'components/common/PeopleDisplay';
import FileDisplay from 'components/common/FileDisplay';
import { FlexBox } from 'utils/styledComponents';
import { Event } from '@mui/icons-material';

const CompanyField = () => {
  const theme = useTheme();
  const formatDate = (
    date: Date,
    format: 'long' | 'short' = 'long',
  ): string => {
    const longOptions: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };
    const shortOptions: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const options = format === 'long' ? longOptions : shortOptions;
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  function renderCompanyField(field: ICompanyField, index: number) {
    switch (field.type) {
      case 'TEXT':
        if (field.text === undefined) return null;
        return <Typography variant={'body2'}>{field.text}</Typography>;

      case 'DATE':
        if (field.localDate === undefined) return null;
        const localDate = new Date(field.localDate);
        const formattedDate = formatDate(localDate, 'long');
        const [day, month, year] = formattedDate.split(' ');
        const finalDate = `${day}. ${month} ${year}`;
        return (
          <FlexBox sx={{ p: '0.5rem 1rem', gap: '0.5rem' }}>
            <Event sx={{ color: theme.palette.primary.main }} />
            <Typography variant={'body2'}>{finalDate}</Typography>
          </FlexBox>
        );

      case 'TAGS':
        if (field.selectedTags === undefined) return null;
        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              p: '0.5rem',
              gap: '0.25rem',
            }}>
            {field.selectedTags.map((tag) => {
              const tagToDisplay = tag.name.startsWith('#')
                ? tag.name
                : `#${tag.name}`;
              return (
                <Box
                  key={tag.id}
                  sx={{
                    fontSize: '0.7rem',
                    lineHeight: '0.7rem',
                    padding: '5px 10px',
                    backgroundColor: theme.palette.primary.light,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '12px',
                    color: '#333333',
                    textAlign: 'center',
                    flexWrap: 'wrap',
                    whiteSpace: 'wrap',
                  }}>
                  {tagToDisplay}
                </Box>
              );
            })}
          </Box>
        );

      case 'RICH_TEXT':
        if (
          field.richText === undefined ||
          field.richText.trim() === '' ||
          field.richText === '<p><br></p>'
        )
          return null;
        return <RichTextDisplay text={field.richText} />;

      case 'FILES':
        if (field.files === undefined) return null;
        return (
          <>
            {field.files.map((file: IUploadFile, fileIndex) => (
              <Box key={fileIndex}>
                <FileDisplay file={file} />
              </Box>
            ))}
          </>
        );

      case 'PEOPLE':
        if (field.people === undefined) return null;
        return (
          <PeopleDisplay
            peoples={field.people}
            companyName={'companyName'}
            sectionTitle={field.name}
          />
        );
      default:
        return null;
    }
  }

  return { renderCompanyField };
};

export default CompanyField;
