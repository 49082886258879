import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Fade, Theme, Typography } from '@mui/material';
import { StageProgress } from './JourneyProgressBar';
import { useJourneyContext } from './JourneyContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.5rem',
    width: '120px',
    borderBottomRightRadius: (props: any) => (props.isLast ? '5px' : '0'),
    textTransform: 'capitalize',

    '& p': {
      fontSize: '0.8rem !important',
      marginTop: '3px !important',
      fontWeight: 600,
    },

    color: theme.palette.primary.dark,
  },

  options: {
    position: 'absolute',
    zIndex: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    top: '0',
  },
}));

const OPTIONS = [
  StageProgress.TODO,
  StageProgress.IN_PROGRESS,
  StageProgress.FINISHED,
];

function getBgColor(option: StageProgress | undefined) {
  return !option || option === StageProgress.TODO
    ? '#eee'
    : option === StageProgress.IN_PROGRESS
    ? '#FFDC8D'
    : '#A2F0CF';
}

function getOptionName(option: StageProgress | undefined) {
  if (!option || option === StageProgress.TODO) return 'To Do';
  if (option === StageProgress.IN_PROGRESS) return 'In Progress';
  return 'Finished';
}

interface IJourneyGoalProgress {
  themeIndex: number;
  milestoneIndex: number;
  progress: StageProgress | undefined;
  isLast: boolean;
  goalId: number | undefined;
  isCustom: boolean | undefined;
  isCustomMilestone: boolean | undefined;
}

const JourneyGoalProgress: FunctionComponent<IJourneyGoalProgress> = (
  props,
) => {
  const { progress, isLast, goalId, themeIndex, milestoneIndex, isCustom, isCustomMilestone } = props;
  const classes = useStyles({ progress, isLast });
  const { updateStageGoalProgress } = useJourneyContext();

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = useState<
    StageProgress | undefined
  >(progress);

  function renderOptions() {
    return OPTIONS.map((option) => (
      <ButtonBase
        key={`goal-${goalId}-${option}`}
        onClick={async () => {        
          if (progress === option) return;            

          setCurrentProgress(option);
          await updateStageGoalProgress(
            themeIndex,
            milestoneIndex,
            goalId,
            option ?? StageProgress.TODO,
            isCustom,
            isCustomMilestone,
          );
        }}>
        {renderOption(option)}
      </ButtonBase>
    ));
  }

  function renderOption(option: StageProgress | undefined) {
    return (
      <div
        className={classes.root}
        style={{ backgroundColor: getBgColor(option) }}>
        <Typography variant={'body1'}>{getOptionName(option)}</Typography>
      </div>
    );
  }

  return (
    <ButtonBase
      onClick={() => setShowOptions(!showOptions)}
      style={{ position: 'relative' }}>
      {renderOption(currentProgress)}
      <Fade in={showOptions} timeout={500}>
        <div className={classes.options}>{renderOptions()}</div>
      </Fade>
    </ButtonBase>
  );
};

export default JourneyGoalProgress;
