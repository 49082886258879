import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  ButtonBase,
  Input,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { IJourneyGoal, IJourneyResources } from 'utils/models';
import LaunchIcon from '@mui/icons-material/Launch';
import { formatNumOf } from 'utils/utils';
import JourneyGoalProgress from './JourneyGoalProgress';
import JourneyGoalResources from './JourneyGoalResources';
import JourneyGoalDeadline from './JourneyGoalDeadline';
import JourneyGoalAssignedPeople from './JourneyGoalAssignedPeople';
import JourneyGoalCustomResources from './JourneyGoalCustomResources';
import { useJourneyContext } from './JourneyContext';
import { Delete } from '@mui/icons-material';
import TypographyBox from '../common/TypographyBox';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  goalBody: {
    borderBottom: (props: any) => (props.isLast ? 'none' : '1px solid #E0E0E0'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: (props: any) => (props.isCustom ? '#fffcef' : 'inherit'),
  },
  cont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.6rem 1rem',
    paddingRight: '0.75rem',
    height: '2.5rem',
    position: 'relative',

    '& p': {
      fontSize: '0.8rem !important',
      marginTop: '3px !important',
    },

    '& svg': {
      height: '18px',
      width: '18px',
      color: theme.palette.primary.dark,
    },

    '& button': {
      borderRadius: '4px !important',
      padding: '3px !important',
      marginTop: '2px !important',
    },
  },
  aside: {
    width: '175px',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.light,
    '& svg': {
      color: theme.palette.primary.dark,
    },

    '& p': {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  pastDeadline: {
    backgroundColor: '#FF5252 !important',
    '& p': {
      color: '#FFEBEE !important',
    },
    '& svg': {
      color: '#FFEBEE !important',
      marginRight: '10px',
    },
  },
  member: {
    height: '37px',
    width: 'auto',
    borderRadius: '50%',
  },
  members: {
    display: 'flex',
    position: 'relative',
    minWidth: '10rem',
    right: '17px',
    overflow: 'hidden',
  },
}));

function getTotalResources(resources: IJourneyResources) {
  const { files, persons, links } = resources;
  return files.length + persons.length + links.length;
}

interface IJourneyGoalProps {
  themeIndex: number;
  milestoneIndex: number;
  index: number;
  goal: IJourneyGoal;
  isLast: boolean;
  isCustom?: boolean;
  isCustomMilestone?: boolean;
}

const JourneyGoal: FunctionComponent<IJourneyGoalProps> = (props) => {
  const {
    index,
    goal,
    isLast,
    themeIndex,
    milestoneIndex,
    isCustom,
    isCustomMilestone,
  } = props;
  const { resources, progress, deadline } = goal;
  const classes = useStyles({ isLast, isCustom });

  const theme = useTheme();

  const [showResources, setShowResources] = useState<boolean>(false);
  const [openCustomResources, setOpenCustomResources] =
    useState<boolean>(false);
  const { isEditJourneyContext, editCustomGoal, deleteCustomGoal } =
    useJourneyContext();
  const showEdit = isCustom && isEditJourneyContext;

  return (
    <div className={classes.root}>
      <div className={classes.goalBody}>
        <div
          className={classes.cont}
          style={{
            width: showEdit ? 300 : 580,
            borderRight: isCustom ? 'none' : '1px solid #E0E0E0',
          }}>
          {showEdit ? (
            <Input
              value={goal.name}
              placeholder='New custom activity'
              onChange={(e) =>
                editCustomGoal(
                  themeIndex,
                  milestoneIndex,
                  index,
                  e.target.value,
                  isCustomMilestone,
                )
              }
              sx={{ fontSize: 13, minWidth: 285, marginRight: 1 }}
            />
          ) : (
            <TypographyBox text={goal.name} />
          )}
        </div>
        {showEdit && (
          <Button
            sx={{ marginRight: 1 }}
            onClick={() =>
              deleteCustomGoal(
                themeIndex,
                milestoneIndex,
                index,
                isCustomMilestone,
              )
            }>
            <Delete sx={{ color: theme.palette.primary.dark }} />
          </Button>
        )}
        {!(isEditJourneyContext && isCustom) && (
          <JourneyGoalDeadline
            progress={progress}
            deadline={deadline ? new Date(deadline) : null}
            goalId={goal.id}
            isCustom={isCustom}
          />
        )}
        {!(isEditJourneyContext && isCustom) && (
          <div
            onClick={() => setShowResources(true)}
            className={classes.cont}
            style={{
              width: '197px',
              borderRight: '1px solid #E0E0E0',
              cursor: 'pointer',
            }}>
            <Typography variant={'body1'} fontWeight={600}>
              {formatNumOf(getTotalResources(resources), 'Resource')}
            </Typography>
            <ButtonBase>
              <LaunchIcon />
            </ButtonBase>
          </div>
        )}
        {!(isEditJourneyContext && isCustom) && (
          <JourneyGoalAssignedPeople goal={goal} isCustom={isCustom} />
        )}
        {!(isEditJourneyContext && isCustom) && (
          <JourneyGoalProgress
            themeIndex={themeIndex}
            milestoneIndex={milestoneIndex}
            progress={progress}
            isLast={isLast}
            goalId={goal.id}
            isCustom={isCustom}
            isCustomMilestone={isCustomMilestone}
          />
        )}
      </div>
      {!(isEditJourneyContext && isCustom) && !isCustom ? (
        <JourneyGoalResources
          goal={goal}
          open={showResources}
          close={() => setShowResources(false)}
        />
      ) : (
        !isEditJourneyContext && (
          <JourneyGoalCustomResources
            goal={goal}
            open={showResources}
            setOpen={setShowResources}
            themeIndex={themeIndex}
            milestoneIndex={milestoneIndex}
            goalIndex={index}
            isCustomMilestone={isCustomMilestone}
          />
        )
      )}
    </div>
  );
};

export default JourneyGoal;
