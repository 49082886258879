import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import Modal from '../common/Modal';
import { HOME_NEW_ANNOUNCEMENT_ROUTE, patchFormData } from 'utils/requests';
import { useParams } from 'react-router';
import { IAnnouncement } from 'utils/models';
import { updateHome } from 'utils/storage';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import RichTextEditor from '../common/RichTextEditor';
import UploadImage from '../common/UploadImage';
import SelectAccessLevels from '../common/SelectAccessLevels';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.55rem',
    maxWidth: '510px',
    gap: '10px',
    '& h6': {
      marginBottom: '5px',
    },
  },
}));

interface IEditAnnouncement {
  open: boolean;
  close: () => void;
  announcements: IAnnouncement[];
  setAnnouncements: (announcement: IAnnouncement[]) => void;
  id: number;
  title: string;
  body: string;
  imageUrl: string | null;
  accessLevelIds: number[];
}

const EditAnnouncement: FunctionComponent<IEditAnnouncement> = (props) => {
  const {
    open,
    close,
    announcements,
    setAnnouncements,
    id,
    title,
    body,
    imageUrl,
    accessLevelIds,
  } = props;
  const { ecosystemName } = useParams();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const { isMobile } = useMobileContext();

  const [editedTitle, setEditedTitle] = useState<string>(title);
  const [editedBody, setEditedBody] = useState<string>(body);
  const [loading, setLoading] = useState<boolean>(false);
  const [editedImage, setEditedImage] = useState<any>(null);
  const [accessLevels, setAccessLevels] = useState<number[]>(
    accessLevelIds ?? [],
  );

  const isAllRequiredFilled =
    editedBody.trim().length > 0 &&
    editedBody !== '<p><br></p>' &&
    accessLevels.length > 0;

  async function editAnnouncement() {
    setLoading(true);
    try {
      const formData = new FormData();

      const announcementRequest = JSON.stringify({
        id,
        title: editedTitle,
        body: editedBody,
        ecosystemName,
        accessLevelIds: accessLevels,
      });

      formData.append(
        'announcementRequest',
        new Blob([announcementRequest], { type: 'application/json' }),
      );

      if (editedImage) {
        formData.append('image', editedImage);
      }

      const data: IAnnouncement = await patchFormData(
        HOME_NEW_ANNOUNCEMENT_ROUTE,
        formData,
      );

      const updatedAnnouncements = announcements.map((announcement) => {
        if (announcement.id === id) {
          return {
            ...announcement,
            title: editedTitle,
            body: editedBody,
            imageUrl: data.imageUrl,
            accessLevelIds: accessLevels,
          };
        }

        return announcement;
      });
      setAnnouncements(updatedAnnouncements);
      updateHome({
        announcements: updatedAnnouncements,
      });

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Announcement was edited',
      });

      close();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderContent() {
    return (
      <div className={classes.main}>
        {/* <Typography variant={'h6'}>Announcement title</Typography>
        <InputField
          value={editedTitle}
          placeholder={'Title'}
          onChange={setEditedTitle}
          small={true}
          borderSize={1}
          readonly={loading}
        /> */}
        <div
          style={{
            minHeight: '200px',
            marginBottom: '35px',
            marginTop: '25px',
          }}>
          <RichTextEditor
            value={editedBody}
            onChange={setEditedBody}
            readonly={loading}
            height={170}
          />
        </div>
        <SelectAccessLevels
          accessLevelIds={accessLevels}
          onAccessGroupChange={setAccessLevels}
        />
        <UploadImage
          initialSrc={imageUrl || undefined}
          width='100%'
          onUpload={(file) => setEditedImage(file)}
          height={200}
          borderColor={theme.palette.primary.dark}
          hideBorder
          full
          showButtons={true}
          onRemove={() => setEditedImage(null)}
          crop={true}
          aspectRatioWidth={4}
          aspectRatioHeight={3}
        />
      </div>
    );
  }

  return (
    <Modal
      width={isMobile ? 320 : 550}
      title={'Edit announcement'}
      open={open}
      close={close}
      showFooter={true}
      largeFooter={true}
      headerColor={'white'}
      headerBorder={true}
      footerButtonsFlexStart={true}
      loading={loading}
      disabledSave={!isAllRequiredFilled}
      isAllRequiredFilled={isAllRequiredFilled}
      saveButtonText={'Update'}
      saveAction={() => editAnnouncement()}
      backgroundColor={'white'}>
      {renderContent()}
    </Modal>
  );
};

export default EditAnnouncement;
