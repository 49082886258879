import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import CollapseList from '../common/CollapseList';
import FolderIcon from '@mui/icons-material/Folder';
import CompanyDocItem from './CompanyDocItem';
import { ICompanyDoc } from 'utils/models';
import CompanyUploadDoc from './CompanyUploadDoc';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface ICompanyAdminDocuments {
  documents: ICompanyDoc[];
  companyName: string;
  updateDocs: (doc: ICompanyDoc, deleted: boolean) => void;
  seeMore: boolean;
  canUploadAdminDocs: boolean;
}

const CompanyAdminDocuments: FunctionComponent<ICompanyAdminDocuments> = (
  props,
) => {
  const { documents, companyName, updateDocs, seeMore, canUploadAdminDocs } =
    props;
  const classes = useStyles();
  const theme = useTheme();

  function renderDocuments() {
    return documents.map((doc, index) => (
      <CompanyDocItem
        key={doc.id}
        companyName={companyName}
        doc={doc}
        index={index}
        updateDocs={updateDocs}
        canUploadAdminDocs={canUploadAdminDocs}
      />
    ));
  }

  return (
    <CollapseList
      title={'Admin Documents'}
      color={theme.palette.primary.dark}
      seeMore={seeMore}
      icon={<FolderIcon />}
      action={
        <CompanyUploadDoc
          docType={'admin'}
          companyName={companyName}
          updateDocs={updateDocs}
          canUploadAdminDocs={canUploadAdminDocs}
        />
      }>
      <div className={classes.root}>{renderDocuments()}</div>
    </CollapseList>
  );
};

export default CompanyAdminDocuments;
