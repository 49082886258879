import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import CollapseList from '../common/CollapseList';
import LinkIcon from '@mui/icons-material/Link';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Modal from '../common/Modal';
import InputField from '../common/InputField';
import CompanyDocItem from './CompanyDocItem';
import { ICompanyDoc } from 'utils/models';
import { COMPANY_ADD_RESOURCE_ROUTE, postData } from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface ICompanyResourceLinks {
  companyName: string;
  updateDocs: (doc: ICompanyDoc, deleted: boolean) => void;
  resourceLinks: ICompanyDoc[];
  canEditCompanyProfile: boolean;
}

const CompanyResourceLinks: FunctionComponent<ICompanyResourceLinks> = (
  props,
) => {
  const { companyName, updateDocs, resourceLinks, canEditCompanyProfile } =
    props;
  const { ecosystemName } = useParams();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [showNewResource, setShowNewResource] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  async function addResource() {
    setLoading(true);

    try {
      const data = await postData(COMPANY_ADD_RESOURCE_ROUTE, [
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'resourceName', value: name },
        { name: 'resourceLink', value: url },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Resource added',
      });

      updateDocs(data, false);
      setShowNewResource(false);
      setName('');
      setUrl('');
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderResourceLinks() {
    return resourceLinks.map((doc, index) => (
      <CompanyDocItem
        key={doc.id}
        companyName={companyName}
        doc={doc}
        index={index}
        updateDocs={updateDocs}
        canEditCompanyProfile={canEditCompanyProfile}
      />
    ));
  }

  function renderAction() {
    if (!canEditCompanyProfile) return undefined;
    return (
      <Button onClick={() => setShowNewResource(true)}>
        <AddCircleIcon /> Add Resource
      </Button>
    );
  }

  function renderContent() {
    return (
      <div>
        <InputField
          value={name}
          placeholder={'Resource name'}
          readonly={loading}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value)
          }
        />
        <InputField
          value={url}
          placeholder={'Resource URL'}
          readonly={loading}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setUrl(event.target.value)
          }
        />
      </div>
    );
  }

  return (
    <>
      <CollapseList
        title={'Resource Links'}
        color={theme.palette.primary.dark}
        action={renderAction()}
        icon={<LinkIcon />}>
        <div className={classes.root}>{renderResourceLinks()}</div>
      </CollapseList>
      <Modal
        open={showNewResource}
        close={() => setShowNewResource(false)}
        title={'Add a Resource'}
        color={theme.palette.primary.main}
        loading={loading}
        disabledSave={!name || !url}
        hideFooterBorder={true}
        largeFooter={true}
        saveAction={() => addResource()}
        showFooter={true}>
        {renderContent()}
      </Modal>
    </>
  );
};

export default CompanyResourceLinks;
