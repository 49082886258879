import React, { FunctionComponent, useEffect, useState } from 'react';
import { Fade, Grid, useTheme } from '@mui/material';
import CompanyHeader from 'components/Companies/CompanyHeader';
import CompanyPeople from 'components/Companies/CompanyPeople';
import CompanyPrivateProfile from 'components/Companies/CompanyPrivateProfile';
import CompanyEditProfile from 'components/Companies/CompanyEditProfile';
import Loading from 'components/common/Loading';
import {
  getCompanyPrivateStatus,
  setCompanyPrivateStatus,
} from 'utils/storage';
import CompanyMain from 'components/Companies/CompanyMain';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { useMobileContext } from 'services/contexts/MobileContext';
import { Box } from '@mui/system';
import CommonButton from 'components/common/CommonButton';
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { acterioTheme, naturalPallete } from 'theme';
import CompanySideSection from 'components/Companies/CompanySideSection';
import Back from 'components/common/Back';
import { trackUmamiEvent } from 'utils/utils';

interface ICompanyProps {}

const Company: FunctionComponent<ICompanyProps> = (props) => {
  const { companyProfile, editProfile, setEditProfile, loading } =
    useCompanyContext();
  const theme = useTheme();
  const privateStatus = getCompanyPrivateStatus();
  const [showPrivate, setShowPrivate] = useState<boolean>(privateStatus);

  const { isMobile } = useMobileContext();

  useEffect(() => {
    setCompanyPrivateStatus(showPrivate);
  }, [showPrivate]);

  function openPDF() {
    trackUmamiEvent('Generate Company PDF');
    const currentUrl = window.location.href;
    const newUrl = `${currentUrl}/pdf`;
    window.open(newUrl, '_blank');
  }

  if (loading) return <Loading marginTop={90} />;
  if (!companyProfile) return <Loading marginTop={90} />;

  const {
    company,
    usedTags,
    unusedTags,
    companyProfileEmployeeDto,
    numberOfEmployees,
    canEditCompanyProfile,
    canDeleteCompanyProfile,
    header,
    main,
    sidebar,
    footer,
    canSeePrivateCompanyProfile,
  } = companyProfile;

  function renderCompanyHeader() {
    return (
      <CompanyHeader
        name={company.name}
        industry={company.industry}
        locationString={company.locationString}
        logoUrl={companyProfile?.logoUrl ?? ''}
        telephone={company.telephone}
        email={company.email}
        address={company.address}
        numberOfEmployees={numberOfEmployees}
        usedTags={usedTags}
        showPrivate={showPrivate}
        setShowPrivate={setShowPrivate}
        canEditCompanyProfile={canEditCompanyProfile}
        setEditProfile={() => setEditProfile(true)}
        canSeePrivateCompanyProfile={canSeePrivateCompanyProfile}
        openPdf={openPDF}
        bannerUrl={company.bannerUrl}
      />
    );
  }

  function renderPublicProfile() {
    return (
      <>
        <Grid item xs={12} md={12} lg={8}>
          {renderCompanyHeader()}
          <CompanyMain main={main} />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <CommonButton
            text='Go to workspace'
            icon={<KeyboardDoubleArrowRight />}
            onClick={() => setShowPrivate(!showPrivate)}
            sx={{
              width: '95%',
              margin: '1.5rem 1rem',
              backgroundColor: acterioTheme.dark,
              '&:hover': {
                backgroundColor: naturalPallete,
                color: naturalPallete.mainBlackFont,
              },
            }}
          />
          <CompanyPeople
            peoples={companyProfileEmployeeDto}
            companyName={company.name}
          />
          <CompanySideSection sidebar={sidebar} />
        </Grid>
      </>
    );
  }

  function renderPrivateProfile() {
    return (
      <>
        {renderCompanyHeader()}
        <CompanyPrivateProfile
          companyName={company.name}
          peoples={companyProfileEmployeeDto}
          about={{
            description: company.description,
            pitchVideoUrl: company.pitchVideoUrl,
            sideBySide: true,
          }}
          problemAndMarket={{
            problem: company.problem,
            solution: company.solution,
            marketSize: company.marketSize,
          }}
          canEditCompanyProfile={canEditCompanyProfile}
        />
        <Grid item xs={3}>
          <CommonButton
            text='Back'
            icon={<KeyboardDoubleArrowLeft />}
            onClick={() => setShowPrivate(!showPrivate)}
            sx={{
              width: '15rem',
              margin: '1.5rem 1rem',
              backgroundColor: acterioTheme.dark,
              '&:hover': {
                backgroundColor: naturalPallete,
                color: naturalPallete.mainBlackFont,
              },
            }}
          />
        </Grid>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
      }}>
      <Box
        sx={{
          width: { xs: '10rem', md: '8%' },
        }}>
        <Back text='Back' />
      </Box>
      <Box
        sx={{
          minHeight: '80vh',
          maxWidth: '120rem',
          width: { xs: '100%', md: '80vw' },
          margin: 'auto',
        }}>
        <Fade in={true} timeout={1000}>
          <Grid container spacing={1.5}>
            {editProfile ? (
              <CompanyEditProfile
                setEditProfile={setEditProfile}
                people={companyProfileEmployeeDto}
                canDeleteCompanyProfile={canDeleteCompanyProfile}
              />
            ) : showPrivate ? (
              renderPrivateProfile()
            ) : (
              renderPublicProfile()
            )}
          </Grid>
        </Fade>
      </Box>
    </Box>
  );
};

export default Company;
