import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Theme, Typography } from '@mui/material';
import { IJourneyStage } from 'utils/models';
import JourneyProgressBar, {
  getProgressKey,
  INITIAL_STAGE_PROGRESS,
  IStageProgress,
} from './JourneyProgressBar';
import { JOURNEY_SET_LOCK_STATUS, postData } from 'utils/requests';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';
import { useParams } from 'react-router';
import { useJourneyContext } from './JourneyContext';
import { getEcosystemInfo } from 'utils/storage';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
    marginBottom: '3px',

    '& p': {
      fontSize: '16px !important',
    },
  },
  lockButton: {
    fontSize: '12px',
    cursor: getEcosystemInfo().accessLevel.canManageJourneys
      ? 'pointer'
      : 'default!important',
  },
  arrowContainer: {
    // position: 'relative',
    width: '0',
    height: '0',
    borderTop: '20px solid transparent', // Adjust the size of the arrow
    borderLeft: '20px solid #007bff', // Adjust the color of the arrow
    borderBottom: '20px solid transparent', // Adjust the size of the arrow
    position: 'absolute',
    top: '50%',
    left: '100%', // Positioned on the right side
    transform: 'translate(-100%, -50%)', // Centering the arrow vertically
  },
  lockButtonBox: {
    display: 'inline-flex',
    borderRadius: '10px',
    position: 'absolute',
    right: '10px',
    top: '1.875rem',
    width: '67px',
    justifyContent: 'center',
  },
}));

const JourneyStage: FunctionComponent<IJourneyStage> = (props) => {
  const classes = useStyles();
  const { stages } = useJourneyContext();
  const stage = stages.find((s) => s.id === props.id);

  if (!stage) {
    console.error(`Stage with id ${props.id} not found in the context.`);
    return null;
  }

  const { name, themes, locked, id } = stage;
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName, companyName } = useParams();
  const { updateLockedStatus } = useJourneyContext();

  const [stageProgress, setStageProgress] = useState<IStageProgress>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getStageProgress();
  }, [props]);

  function getStageProgress() {
    const stageProgressCopy = { ...INITIAL_STAGE_PROGRESS };

    themes.forEach(({ milestones }) => {
      milestones.forEach(({ goals }) => {
        if (!goals || goals.length === 0) return;

        goals.forEach(({ progress }) => {
          const key = getProgressKey(progress);
          stageProgressCopy[key] = stageProgressCopy[key] + 1;
        });
      });
    });

    setStageProgress(stageProgressCopy);
  }

  async function updateLockStatus(lockStatus: boolean) {
    setLoading(true);
    if (!companyName) return;
    try {
      await postData(JOURNEY_SET_LOCK_STATUS, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'journeyStageId', value: id },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'lockStatus', value: lockStatus },
      ]);

      updateLockedStatus(props, lockStatus);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Lock Status Updated',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  function renderLockStatus() {
    if (locked) {
      return (
        <>
          <div
            className={classes.lockButtonBox}
            style={{
              backgroundColor: '#d4d4d4',
            }}>
            <LockOutlinedIcon sx={{ width: '12px!important' }} />
            <ButtonBase
              className={classes.lockButton}
              disabled={
                !getEcosystemInfo().accessLevel.canManageJourneys || loading
              }
              onClick={() => updateLockStatus(false)}>
              Locked
            </ButtonBase>
          </div>
        </>
      );
    } else {
      return (
        <div
          className={classes.lockButtonBox}
          style={{
            backgroundColor: '#cae7ea',
          }}>
          <LockOpenIcon sx={{ width: '12px!important' }} />
          <ButtonBase
            className={classes.lockButton}
            disabled={
              !getEcosystemInfo().accessLevel.canManageJourneys || loading
            }
            onClick={() => updateLockStatus(true)}>
            Unlocked
          </ButtonBase>
        </div>
      );
    }
  }

  return (
    <div>
      <div className={classes.root}>
        <Typography variant={'body1'}>{name}</Typography>
        <JourneyProgressBar progress={stageProgress} />
      </div>
      {renderLockStatus()}
    </div>
  );
};

export default JourneyStage;
