import { Typography } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import RichTextEditor from 'components/common/RichTextEditor';
import { IEvent } from 'utils/models';
import { Box } from '@mui/system';

interface IAddEventDesc {
  event: IEvent;
  setEvent: (event: IEvent) => void;
  isLoading: boolean;
}

const AddEventDescription: FunctionComponent<IAddEventDesc> = (props) => {
  const { event, setEvent, isLoading } = props;

  const [description, setDescription] = useState<string>(event.description);

  useEffect(() => {
    const updatedEvent = { ...event, description: description };
    setEvent(updatedEvent);
  }, [description]);

  return (
    <Box sx={{ height: 230 }}>
      <Typography
        variant='body1'
        sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
        Event Description*
      </Typography>
      <div style={{ display: 'flex', height: '12.5rem' }}>
        <RichTextEditor
          value={description}
          onChange={setDescription}
          readonly={isLoading}
          width={100}
          height={150}
          widthStyle={'%'}
        />
      </div>
    </Box>
  );
};

export default AddEventDescription;
