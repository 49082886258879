import React, { FunctionComponent } from 'react';
import { ICompanyField } from 'utils/models';
import CompanySection from './CompanySection';
import RichTextDisplay from '../common/RichTextDisplay';
import { Box } from '@mui/material';
import { isEmptyRichText } from 'utils/utils';

export interface ICompanyMain {
  main: ICompanyField[];
}

const CompanyMain: FunctionComponent<ICompanyMain> = (props) => {
  const { main } = props;

  function renderMain() {
    return main.map((field, index) => {
      if (isEmptyRichText(field.richText)) {
        return null;
      }

      return (
        <Box sx={{ marginBottom: '1rem' }} key={'main-' + index}>
          <CompanySection title={field.name}>
            <RichTextDisplay text={field.richText as string} />
          </CompanySection>
        </Box>
      );
    });
  }

  return <>{renderMain()}</>;
};

export default CompanyMain;
