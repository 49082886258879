import React, { FunctionComponent, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Typography } from '@mui/material';
import { ICompanyGoals } from './CompanyGoals';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import clsx from 'clsx';
import { Delete } from '@mui/icons-material';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { COMPANY_DELETE_GOAL_ROUTE, deleteData } from 'utils/requests';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '20px 10px',
    position: 'relative',
  },
  header: {
    paddingLeft: '15px',
    paddingRight: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',

    '& h6': {
      fontSize: '0.75rem !important',
      color: `${theme.palette.primary.main} !important`,
    },
  },
  main: {
    '& ul': {
      maxHeight: '28.125rem',
      overflowY: 'auto',
    },
  },
  aside: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '35px',

    '& h6': {
      marginLeft: '4.375rem',
    },
  },
  asideList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& h6': {
      width: '6.25rem',
      textAlign: 'left',
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 7.5px',
    transition: '0.3s all linear',
    borderRadius: '4px',
    borderBottom: '1px solid #eee',
    marginRight: '5px',

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },

    '& svg': {
      height: '20px',
      width: '20px',
      color: theme.palette.primary.main,
      marginRight: '20px',
      marginBottom: '-5px',
      marginLeft: '5px',
    },

    '& h6': {
      textTransform: 'capitalize',
      fontSize: '0.7rem !important',
    },
  },
  ul: {
    '&::-webkit-scrollbar': {
      width: '0.6em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 12px #E0E0E0',
      webkitBoxShadow: 'inset 0 0 12px #E0E0E0',
      borderRadius: '0px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#BDBDBD',
      borderRadius: '0px',
    },
  },
  pastDeadline: {
    backgroundColor: '#FF5252 !important',

    '& svg': {
      color: '#FFEBEE !important',
    },

    '& h6': {
      color: '#FFEBEE !important',
    },
  },
}));

interface ICompanyGoalsHistory extends ICompanyGoals {
  pastDeadline: (date: Date) => boolean;
}

const CompanyGoalsHistory: FunctionComponent<ICompanyGoalsHistory> = (
  props,
) => {
  const { goals, pastDeadline, updateGoals, companyName } = props;
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  async function deleteGoal(id: number) {
    try {
      await deleteData(COMPANY_DELETE_GOAL_ROUTE, [
        { name: 'goalId', value: id },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Goal deleted',
      });

      updateGoals(goals.filter((goal) => goal.id !== id));
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function renderGoals() {
    return goals.map((goal, i) => {
      const { id, goalTitle, deadline, achieved, achievedDate } = goal;
      const isPastDeadline = !achieved && pastDeadline(new Date(deadline));

      return (
        <li
          key={`history-${id}-index-${i}`}
          className={clsx(classes.listItem, {
            [classes.pastDeadline]: isPastDeadline,
          })}>
          <Typography variant={'h6'}>
            {achieved ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
            {goalTitle}
          </Typography>
          <div className={classes.asideList}>
            <Typography variant={'h6'} style={{ marginLeft: '20px' }}>
              {deadline}
            </Typography>
            <Typography variant={'h6'} style={{ marginLeft: '20px' }}>
              {achievedDate ?? 'In Progress'}
            </Typography>
            <Button sx={{ marginLeft: '20px' }} onClick={() => deleteGoal(id)}>
              <Delete sx={{ left: 4 }} />
            </Button>
          </div>
        </li>
      );
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant={'h6'}>Goal list</Typography>
        <div className={classes.aside}>
          <Typography variant={'h6'}>Deadline</Typography>
          <Typography variant={'h6'}>Achieved</Typography>
          <Typography variant={'h6'} sx={{ marginRight: -3 }}>
            Delete
          </Typography>
        </div>
      </div>
      <div className={classes.main}>
        <ul
          style={{ paddingLeft: 0, marginTop: 0, listStyleType: 'none' }}
          className={classes.ul}>
          {renderGoals()}
        </ul>
      </div>
    </div>
  );
};

export default CompanyGoalsHistory;
