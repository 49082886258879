import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { Controller, FieldErrors } from 'react-hook-form';
import {
  IApplicationAssessmentOption,
  IApplicationAssessmentTemplate,
} from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import { ControlButtonStyled } from '../ApplicationBuilder';
import { primary } from 'theme';
import { Add, Close } from '@mui/icons-material';
import InputField from 'components/common/InputField';
import AssessmentBuilderSubSections from './AssessmentBuilderSubSections';

interface IAssessmentBuilderSection {
  control: any;
  errors: FieldErrors<IApplicationAssessmentTemplate>;
  sectionIndex: number;
  resetField: any;
  newOptions: IApplicationAssessmentOption[];
  appendSection: (a: any) => any;
  removeSection: (a: any) => any;
}

const AssessmentBuilderSection: FunctionComponent<IAssessmentBuilderSection> = (
  props,
) => {
  const {
    control,
    sectionIndex,
    errors,
    resetField,
    newOptions,
    appendSection,
    removeSection,
  } = props;
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          marginTop: '1.25rem',
        }}>
        <Typography variant={'h6'} sx={{ fontWeight: 'bold', ml: '3.125rem' }}>
          Section name*
        </Typography>
        <FlexBox
          sx={{
            marginBottom: '1.25rem',
          }}>
          <Box sx={{ width: '3rem' }} />
          <Controller
            name={`sections.${sectionIndex}.name`}
            control={control}
            rules={{
              required: sectionIndex !== 0 && 'Please, enter a name of section',
            }}
            render={({ field }) => (
              <InputField
                {...field}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => {
                  field.onChange(e);
                }}
                helperText={errors.sections && errors.sections.message}
                helperIcon={errors.sections && 'warning'}
                error={!!errors?.sections?.[sectionIndex]?.name}
                width={'31.25rem'}
                maxLength={255}
                placeholder={'Create a name for the section'}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    opacity: sectionIndex === 0 ? '200%' : '100%',
                    '& .MuiInputBase-input': {
                      WebkitTextFillColor: primary.mainBlackFont,
                    },
                  },
                }}
              />
            )}
          />

          <ControlButtonStyled
            disabled={sectionIndex === 0}
            onClick={() => removeSection(sectionIndex)}>
            <Close />
          </ControlButtonStyled>
        </FlexBox>

        <AssessmentBuilderSubSections
          resetField={resetField}
          errors={errors}
          control={control}
          sectionIndex={sectionIndex}
          newOptions={newOptions}
        />
      </Box>
      <ControlButtonStyled
        sx={{
          '& .MuiSvgIcon-root, .MuiBox-root': {
            transition: 'all 0.3s ease-in-out',
          },
          ':hover': {
            '& .MuiSvgIcon-root': {
              color: primary.pureWhite,
            },
            '& .MuiBox-root': {
              backgroundColor: primary.dark,
            },
          },
          mt: '1rem',
        }}
        onClick={() =>
          appendSection({
            name: '',
            subSections: [
              {
                name: '',
                questions: [
                  {
                    name: '',
                    options: newOptions,
                  },
                ],
              },
            ],
          })
        }>
        <Box
          sx={{
            border: '1px solid black',
            borderRadius: '100%',
            lineHeight: '0.75',
            backgroundColor: primary.pureWhite,
          }}>
          <Add
            sx={{
              fontSize: '2rem',
            }}
          />
        </Box>
        <Typography
          sx={{
            marginLeft: '0.625rem',
            fontSize: '1.15rem',
          }}>
          Add a new section
        </Typography>
      </ControlButtonStyled>
    </>
  );
};

export default AssessmentBuilderSection;
