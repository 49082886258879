import { Button, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState, useEffect } from 'react';

import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { primary } from 'theme';
import Request from './Request';
import {
  MEETING_MY_REQUESTS_ROUTE,
  MEETING_USER_REQUESTS_ROUTE,
  getData,
} from 'utils/requests';
import { formatDateDD } from 'utils/utils';
import Loading from 'components/common/Loading';
import { IRequest } from 'utils/models';
import { getEcosystemInfo } from 'utils/storage';

const Requests = () => {
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const theme = useTheme();

  const accessLevels = getEcosystemInfo().accessLevel;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isPendingLoading, setIsPendingLoading] = useState<boolean>(false);
  const [isRequestsLoading, setIsRequestsLoading] = useState<boolean>(false);

  const [requests, setRequests] = useState<IRequest[]>([]);
  const [isMyRequests, setIsMyRequests] = useState<boolean>(
    accessLevels.canScheduleMeetings,
  );
  const [pendingRequests, setPendingRequests] = useState<IRequest[]>([]);
  const [requestsMadeByMe, setRequestsMadeByMe] = useState<IRequest[]>([]);

  const currentDate = new Date();

  useEffect(() => {
    if (accessLevels.canSetAvailability) {
      fetchPendingRequests();
    }
    if (accessLevels.canScheduleMeetings) {
      fetchRequestsMadeByMe();
    }
  }, []);

  useEffect(() => {
    if (isMyRequests) {
      setRequests(sortRequests(requestsMadeByMe));
    } else {
      setRequests(sortRequests(pendingRequests));
    }
  }, [isMyRequests, requestsMadeByMe, pendingRequests]);

  async function fetchPendingRequests() {
    setIsLoading(true);
    setIsPendingLoading(true);

    try {
      const data = await getData(MEETING_USER_REQUESTS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setPendingRequests(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
    setIsPendingLoading(false);
  }

  async function fetchRequestsMadeByMe() {
    setIsLoading(true);
    setIsRequestsLoading(true);

    try {
      const data = await getData(MEETING_MY_REQUESTS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setRequestsMadeByMe(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
    setIsRequestsLoading(false);
  }

  function updateRequests(requests: IRequest[]) {
    setRequests(requests);
    if (isMyRequests) {
      setRequestsMadeByMe(requests);
    } else {
      setPendingRequests(requests);
    }
  }

  function sortRequests(requests: IRequest[]) {
    return requests.slice().sort((requestA, requestB) => {
      const dateA = new Date(requestA.date);
      const dateB = new Date(requestB.date);

      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  function renderDays() {
    let lastDay: Date | null = null;
    return (
      <Box padding={4}>
        {requests.length ? (
          requests.map((request, index) => {
            const isANewDate = !(
              new Date(request.date).setHours(0, 0, 0, 0) ===
              (lastDay === null ? lastDay : lastDay.setHours(0, 0, 0, 0))
            );
            if (isANewDate) lastDay = new Date(request.date);
            const isInThePast =
              lastDay &&
              lastDay.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0);
            return (
              <Box key={`${request.id} + ${index}`}>
                {isANewDate && (
                  <Typography
                    fontWeight='bold'
                    fontSize={18}
                    sx={{ color: isInThePast ? 'red' : 'none' }}>
                    {lastDay && formatDateDD(lastDay)}
                  </Typography>
                )}
                {isInThePast && (
                  <Typography>
                    This request has a short deadline or is past the meeting
                    time!
                  </Typography>
                )}
                <Request
                  request={request}
                  isMyRequests={isMyRequests}
                  setRequests={updateRequests}
                />
              </Box>
            );
          })
        ) : (
          <Typography color={theme.palette.primary.main}>
            {isMyRequests
              ? 'You have no pending requests for mentor sessions at the moment...'
              : 'You have no pending meeting requests at the moment...'}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 900 }}>
      <Box justifyContent='left' marginTop='4rem' marginBottom='2rem'>
        <Typography variant='h5' gutterBottom fontSize={22}>
          Booking requests
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          minHeight: 300,
          bgcolor: '#F0F0F0',
          border: 1,
          borderColor: '#D4D4D4',
          borderRadius: '6px',
        }}>
        <Box
          sx={{
            bgcolor: '#FFFFFF',
            borderBottom: 1,
            borderColor: '#D4D4D4',
            borderRadius: '6px',
            padding: '2rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: { xs: 'center', md: 'left' },
          }}>
          {accessLevels.canSetAvailability && (
            <Button
              onClick={() => setIsMyRequests(false)}
              sx={{
                backgroundColor: !isMyRequests
                  ? theme.palette.primary.light
                  : 'default',
                borderRadius: '6px',
                marginTop: 0,
                '&:hover': { bgcolor: theme.palette.primary.contrastText },
              }}>
              <Box
                sx={{
                  marginLeft: 1,
                  borderRadius: 12,
                  width: 24,
                  height: 24,
                  color: theme.palette.primary.main,
                  bgcolor: theme.palette.primary.main,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography sx={{ color: primary.pureWhite }}>
                  {isPendingLoading ? (
                    <Loading color={primary.pureWhite} size={10} />
                  ) : !isMyRequests ? (
                    requests.length
                  ) : (
                    pendingRequests.length
                  )}
                </Typography>
              </Box>
              <Typography
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  color: theme.palette.primary.main,
                }}>
                Pending
              </Typography>
            </Button>
          )}
          {accessLevels.canScheduleMeetings && (
            <Button
              onClick={() => setIsMyRequests(true)}
              sx={{
                backgroundColor: isMyRequests
                  ? theme.palette.primary.light
                  : 'default',
                borderRadius: '6px',
                marginLeft: '1rem',
                marginTop: 0,
                '&:hover': { bgcolor: theme.palette.primary.contrastText },
              }}>
              <Box
                sx={{
                  marginLeft: 1,
                  borderRadius: 12,
                  width: 24,
                  height: 24,
                  color: theme.palette.primary.main,
                  bgcolor: theme.palette.primary.main,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography sx={{ color: primary.pureWhite }}>
                  {isRequestsLoading ? (
                    <Loading color={primary.pureWhite} size={10} />
                  ) : isMyRequests ? (
                    requests.length
                  ) : (
                    requestsMadeByMe.length
                  )}
                </Typography>
              </Box>
              <Typography
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  color: theme.palette.primary.main,
                }}>
                Your requests
              </Typography>
            </Button>
          )}
        </Box>
        {isLoading ||
        (isMyRequests && isRequestsLoading) ||
        (!isMyRequests && isPendingLoading) ? (
          <Loading />
        ) : (
          renderDays()
        )}
      </Box>
    </Box>
  );
};

export default Requests;
