import React, { FunctionComponent, useContext } from 'react';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import CommonButton from 'components/common/CommonButton';

interface ICompanyEditSave {
  onClick: () => void;
  text?: string;
  disabled?: boolean;
  loading?: boolean;
}

const CompanyEditSave: FunctionComponent<ICompanyEditSave> = (props) => {
  const { onClick, text, loading, disabled } = props;
  const { addAlert } = useContext(AlertContext);
  function handleClickError() {
    try {
      onClick();
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  return (
    <CommonButton
      text={text ?? 'Save'}
      onClick={handleClickError}
      isLoading={loading}
      isDisabled={disabled}
      sx={{
        marginRight: '1rem',
        paddingY: '1.2rem',
      }}
    />
  );
};

export default CompanyEditSave;
