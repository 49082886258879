import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Fade, Theme, Typography } from '@mui/material';
import CompanySection from './CompanySection';
import { AddTask, Close, Edit } from '@mui/icons-material';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import {
  COMPANY_ADD_MARKET_ROUTE,
  COMPANY_ADD_PROBLEM_ROUTE,
  COMPANY_ADD_SOLUTION_ROUTE,
  putData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import RichTextEditor from '../common/RichTextEditor';
import RichTextDisplay from '../common/RichTextDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  problem: {
    marginBottom: '15px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '3px',

    '& button': {
      padding: '5px 7.5px !important',
      borderRadius: '4px !important',
      marginRight: '5px',

      '&:disabled': {
        opacity: 0.5,
      },

      '& p': {
        marginTop: '3px',
        fontSize: '0.7rem !important',
        color: theme.palette.primary.dark,
        textDecoration: 'underline !important',
      },

      '& h6': {
        fontSize: '0.85rem !important',
        color: theme.palette.primary.dark,
      },

      '& svg': {
        height: '20px',
        width: '20px',
        marginRight: '5px',
        marginBottom: '-5px',
        color: theme.palette.primary.dark,
      },
    },
  },
  newGoal: {
    display: 'flex',
    marginBottom: '1rem',
    margin: '0.45rem 1.5rem',
    marginRight: '2.35rem',
  },
}));

export interface IProblemAndMarket {
  problem: string;
  solution: string;
  marketSize: string;
}

interface ICompanyProblem {
  problemAndMarket: IProblemAndMarket;
}

const CompanyProblemAndMarket: FunctionComponent<ICompanyProblem> = (props) => {
  const { problemAndMarket } = props;
  const { problem, solution, marketSize } = problemAndMarket;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const classes = useStyles();
  const { companyProfile, setCompanyProfile } = useCompanyContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showProblem, setShowProblem] = useState<boolean>(false);
  const [newProblem, setNewProblem] = useState<string>(problem ?? '');

  const [showSolution, setShowSolution] = useState<boolean>(false);
  const [newSolution, setNewSolution] = useState<string>(solution ?? '');

  const [showMarket, setShowMarket] = useState<boolean>(false);
  const [newMarket, setNewMarket] = useState<string>(marketSize ?? '');

  const canEditCompanyProfile = companyProfile?.canEditCompanyProfile;

  function containsNonTagCharacters(htmlString: string): boolean {
    // Parse the HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Get the text content, which strips away HTML tags
    const textContent = doc.body.textContent || '';

    // Check if there are any non-whitespace characters
    return /\S/.test(textContent);
  }

  function updateCompanyValues(value: string, param: keyof IProblemAndMarket) {
    if (!companyProfile) return;

    const updatedCompany = {
      ...companyProfile.company,
      [param]: value,
    };

    const updatedCompanyProfile = {
      ...companyProfile,
      company: updatedCompany,
    };

    setCompanyProfile(updatedCompanyProfile);
  }

  function clearValue(param: keyof IProblemAndMarket) {
    switch (param) {
      case 'problem':
        setShowProblem(false);
        break;
      case 'solution':
        setShowSolution(false);
        break;
      case 'marketSize':
        setShowMarket(false);
        break;
      default:
        return;
    }
  }

  async function saveNewProblemOrMarket(
    value: string,
    param: keyof IProblemAndMarket,
  ) {
    setIsLoading(true);

    let pathURL = null;

    if (param === 'problem') pathURL = COMPANY_ADD_PROBLEM_ROUTE;

    if (param === 'solution') pathURL = COMPANY_ADD_SOLUTION_ROUTE;

    if (param === 'marketSize') pathURL = COMPANY_ADD_MARKET_ROUTE;

    if (!pathURL || !companyProfile) return;

    try {
      const data = await putData(pathURL, [
        { name: `${param}Value`, value: encodeURIComponent(value) },
        {
          name: 'companyName',
          value: encodeURIComponent(companyProfile?.company.name),
        },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      updateCompanyValues(data, param);
      clearValue(param);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderNewProblem() {
    return (
      <Fade in={true}>
        <div className={classes.newGoal}>
          <div style={{ marginBottom: '2rem', width: '100%' }}>
            <RichTextEditor
              value={newProblem}
              onChange={setNewProblem}
              readonly={isLoading}
              width={100}
              height={150}
              widthStyle={'%'}
            />
          </div>
        </div>
      </Fade>
    );
  }

  function renderProblemActions() {
    return (
      <div className={classes.actions}>
        {canEditCompanyProfile && (
          <Button
            disabled={
              isLoading ||
              (showProblem && !containsNonTagCharacters(newProblem))
            }
            onClick={() =>
              showProblem
                ? saveNewProblemOrMarket(newProblem, 'problem')
                : setShowProblem(true)
            }>
            <Typography variant={'h6'}>
              {showProblem ? <AddTask /> : <Edit />}
              {showProblem ? 'Save' : `${!problem ? 'Add' : 'Edit'} Problem`}
            </Typography>
          </Button>
        )}
        {showProblem && (
          <Button onClick={() => setShowProblem(false)}>
            <Typography variant={'h6'}>
              {<Close />}
              Cancel
            </Typography>
          </Button>
        )}
      </div>
    );
  }

  function renderNewSolution() {
    return (
      <Fade in={true}>
        <div className={classes.newGoal}>
          <div style={{ marginBottom: '2rem', width: '100%' }}>
            <RichTextEditor
              value={newSolution}
              onChange={setNewSolution}
              readonly={isLoading}
              width={100}
              height={150}
              widthStyle={'%'}
            />
          </div>
        </div>
      </Fade>
    );
  }

  function renderSolutionActions() {
    return (
      <div className={classes.actions}>
        {canEditCompanyProfile && (
          <Button
            disabled={
              isLoading ||
              (showSolution && !containsNonTagCharacters(newSolution))
            }
            onClick={() =>
              showSolution
                ? saveNewProblemOrMarket(newSolution, 'solution')
                : setShowSolution(true)
            }>
            <Typography variant={'h6'}>
              {showSolution ? <AddTask /> : <Edit />}
              {showSolution ? 'Save' : `${!solution ? 'Add' : 'Edit'} Solution`}
            </Typography>
          </Button>
        )}
        {showSolution && (
          <Button onClick={() => setShowSolution(false)}>
            <Typography variant={'h6'}>
              {<Close />}
              Cancel
            </Typography>
          </Button>
        )}
      </div>
    );
  }

  function renderNewMarket() {
    return (
      <Fade in={true}>
        <div className={classes.newGoal}>
          <div style={{ marginBottom: '2rem', width: '100%' }}>
            <RichTextEditor
              value={newMarket}
              onChange={setNewMarket}
              readonly={isLoading}
              width={100}
              height={150}
              widthStyle={'%'}
            />
          </div>
        </div>
      </Fade>
    );
  }

  function renderMarketActions() {
    return (
      <div className={classes.actions}>
        {canEditCompanyProfile && (
          <Button
            disabled={
              isLoading || (showMarket && !containsNonTagCharacters(newMarket))
            }
            onClick={() =>
              showMarket
                ? saveNewProblemOrMarket(newMarket, 'marketSize')
                : setShowMarket(true)
            }>
            <Typography variant={'h6'}>
              {showMarket ? <AddTask /> : <Edit />}
              {showMarket ? 'Save' : `${!marketSize ? 'Add' : 'Edit'} Market`}
            </Typography>
          </Button>
        )}
        {showMarket && (
          <Button onClick={() => setShowMarket(false)}>
            <Typography variant={'h6'}>
              {<Close />}
              Cancel
            </Typography>
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.problem}>
        <CompanySection title={'Problem'} headerAction={renderProblemActions()}>
          <>
            {showProblem ? (
              renderNewProblem()
            ) : (
              <RichTextDisplay
                text={problem ?? 'No problem has been added yet'}
                style={{ fontSize: 12, textOverflow: 'ellipsis' }}
              />
            )}
          </>
        </CompanySection>
      </div>
      <div className={classes.problem}>
        <CompanySection
          title={'Solution'}
          headerAction={renderSolutionActions()}>
          <>
            {showSolution ? (
              renderNewSolution()
            ) : (
              <RichTextDisplay
                text={solution ?? 'No solution has been added yet'}
                style={{
                  fontSize: 12,
                  textOverflow: 'ellipsis',
                }}
              />
            )}
          </>
        </CompanySection>
      </div>
      <div className={classes.problem}>
        <CompanySection
          title={'Market Size'}
          headerAction={renderMarketActions()}>
          <>
            {showMarket ? (
              renderNewMarket()
            ) : (
              <RichTextDisplay
                text={marketSize ?? 'No market has been added yet'}
                style={{ fontSize: 12, textOverflow: 'ellipsis' }}
              />
            )}
          </>
        </CompanySection>
      </div>
    </div>
  );
};

export default CompanyProblemAndMarket;
