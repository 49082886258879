import React, { FunctionComponent, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CompanySection from './CompanySection';
import { ICompanyProfileEmployeeDTO } from 'utils/models';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { FlexBox } from 'utils/styledComponents';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import CommonButton from 'components/common/CommonButton';
import { acterioTheme, naturalPallete } from 'theme';
import { KeyboardArrowRight } from '@mui/icons-material';
import CommonModal from 'components/common/Modal/CommonModal';
import ModalHeader from 'components/common/Modal/ModalHeader';

export interface IPeople {
  userId: number;
  profPicUrl: string;
  name: string;
  companyRole: string;
  email: string;
}

interface ICompanyPeople {
  peoples: ICompanyProfileEmployeeDTO[];
  small?: boolean;
  accessLevelName?: string;
  accessLevelColor?: string;
  open?: boolean;
  close?: () => void;
  companyName?: string;
}

const CompanyPeople: FunctionComponent<ICompanyPeople> = (props) => {
  const {
    peoples,
    small,
    companyName,
    accessLevelName,
    accessLevelColor,
    open,
    close,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { ecosystemName } = useParams();
  const navigate = useNavigate();

  function renderPeoples() {
    return peoples.slice(0, 3).map((people) => (
      <Grid
        item
        key={people.userId}
        onClick={() =>
          navigate(`/ecosystem/${ecosystemName}/members/${people.userId}`)
        }>
        <FlexBox sx={{ mb: '1rem' }}>
          <Box sx={{ mr: '1rem', height: '3rem' }}>
            <img
              src={people.profPicUrl}
              style={{
                height: '3rem',
                width: '3rem',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            />
          </Box>
          <Box sx={{ justifyItems: 'flex-start' }}>
            <FlexBox>
              <Typography variant={'h6'}>{people.name}</Typography>
              <AccessLevelTagCard
                accessLevelName={people.accessLevelName || 'Default'}
                accessLevelColor={people.accessLevelColor}
                left={'1rem'}
                height={'1.3rem'}
              />
            </FlexBox>
            <Typography variant={'body1'}>{people.companyRole}</Typography>
          </Box>
        </FlexBox>
      </Grid>
    ));
  }

  function renderSeeMoreTeamButton() {
    return (
      <FlexBox className='end'>
        <CommonButton
          text='See more'
          onClick={openModal}
          bgcolor='none'
          color={naturalPallete.mainBlackFont}
          endIcon={<KeyboardArrowRight />}
          sx={{
            width: '10rem',
            padding: '0rem',
            '&:hover': {
              backgroundColor: naturalPallete,
            },
          }}
        />
      </FlexBox>
    );
  }

  function renderSeeMoreTeamModal() {
    return (
      <CommonModal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            width: '37rem',
            maxHeight: '40rem',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: naturalPallete.natural5 + ' transparent',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            paddingY: '1.5rem',
            paddingX: '2rem',
            background: acterioTheme.pureWhite,
            borderRadius: '10px',
          }}>
          <ModalHeader
            handleClose={closeModal}
            text={`Team in ${companyName}`}
            textColor={acterioTheme.mainBlackFont}
          />
          <Grid container spacing={1} sx={{ textAlign: 'center' }}>
            {peoples.map((people) => (
              <Grid
                item
                xs={3}
                key={people.userId}
                onClick={() =>
                  navigate(
                    `/ecosystem/${ecosystemName}/members/${people.userId}`,
                  )
                }>
                <img
                  src={people.profPicUrl}
                  style={{
                    height: '6rem',
                    width: '6rem',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                />

                <Typography variant={'h6'}>{people.name}</Typography>

                <Typography variant={'body1'}>{people.companyRole}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CommonModal>
    );
  }

  return (
    <CompanySection title={'Team'}>
      <>
        <Box sx={{ padding: '2rem 1rem 1rem 1.5rem' }}>
          {renderPeoples()}
          {renderSeeMoreTeamButton()}
        </Box>
        {renderSeeMoreTeamModal()}
      </>
    </CompanySection>
  );
};

export default CompanyPeople;
