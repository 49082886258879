import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  CompanyProfileFieldType,
  CompanyTemplateSectionType,
  ICompanyProfileField,
} from 'utils/models';
import { useCompanyTemplateContext } from './CompanyTemplateContext';
import { CompanyTemplateFieldContainer } from './components/CompanyTemplateFieldContainer';
import { CompanyTemplateAddSectionModal } from './components/CompanyTemplateAddSectionModal';

export const CompanyTemplateSideBarSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSectionFieldsAdded, setSectionFieldsAdded] = useState(false);
  const { companyTemplate, addSectionField, setSectionField } =
    useCompanyTemplateContext();
  const isTeamField = companyTemplate?.sidebar.some(
    (field) => field.name === 'Team',
  );

  const initialSideBarSectionFields: ICompanyProfileField[] = [
    {
      name: 'Mentors',
      type: CompanyProfileFieldType.PEOPLE,
      required: false,
    },
    {
      name: 'Pitch Deck',
      type: CompanyProfileFieldType.FILES,
      required: true,
    },
    {
      name: 'Needs',
      type: CompanyProfileFieldType.RICH_TEXT,
      required: true,
    },
    {
      name: 'Date joined',
      type: CompanyProfileFieldType.DATE,
      required: false,
    },
  ];

  useEffect(() => {
    if (!companyTemplate) return;

    if (companyTemplate.sidebar.length === 0 && !isSectionFieldsAdded) {
      initialSideBarSectionFields.forEach((field) => {
        addSectionField(CompanyTemplateSectionType.SIDEBAR, field);
      });
      setSectionFieldsAdded(true);
    }
  }, [companyTemplate]);

  function handleOpenModal() {
    setIsModalOpen(true);
    setSectionField({
      name: '',
      type: CompanyProfileFieldType.TAGS,
      required: false,
    });
  }

  function renderSectionFields() {
    return companyTemplate?.sidebar.map((sectionField, index) => (
      <CompanyTemplateFieldContainer
        key={index}
        sectionType={CompanyTemplateSectionType.SIDEBAR}
        {...sectionField}
      />
    ));
  }

  function renderModal() {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
          }}>
          <CommonButton
            onClick={() => handleOpenModal()}
            icon={<AddCircleIcon />}
            text={'Add field'}
            variant={ButtonVariant.BLANK}
          />
        </Box>
        <CompanyTemplateAddSectionModal
          sectionType={CompanyTemplateSectionType.SIDEBAR}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {!isTeamField && (
          <CompanyTemplateFieldContainer
            key='Team-Container'
            sectionType={CompanyTemplateSectionType.SIDEBAR}
            name='Team'
            type={CompanyProfileFieldType.PEOPLE}
            required={true}
          />
        )}
        {renderSectionFields()}
        {renderModal()}
      </Box>
    </>
  );
};
