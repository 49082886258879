import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Divider, Fade, Typography, useTheme } from '@mui/material';
import { boxShadow, primary } from 'theme';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Person from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  clearAllStorage,
  existsEcosystem,
  getAccount,
  getEcosystem,
  getEcosystemInfo,
} from 'utils/storage';
import { useLocation } from 'react-router';
import { getEcosystemNameFromPath, previewText } from 'utils/utils';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { Box } from '@mui/system';
import { ISavedCompanyInfo } from 'utils/models';
import { ArrowForwardIos, Business, Circle } from '@mui/icons-material';
import {
  defaultSchemes,
  useCustomTheme,
} from 'services/contexts/Themes/ThemeContext';

interface IUserMenu {
  show: boolean;
  inActerio: boolean;
}

const UserMenu: FunctionComponent<IUserMenu> = (props) => {
  const { show, inActerio } = props;
  const navigate = useNavigate();
  const { userId } = getAccount();
  const location = useLocation();
  const { addAlert } = useContext(AlertContext);

  const theme = useTheme();

  const { setCurrentTheme } = useCustomTheme();

  const [companies, setCompanies] = useState<ISavedCompanyInfo[]>([]);
  const [ecosystemName, setEcosystemName] = useState<string>('');

  const storedCompanyAndJourneyList =
    getEcosystemInfo().companyNameAndJourneyList;

  const seeIfCompanyListChanged =
    (storedCompanyAndJourneyList && storedCompanyAndJourneyList.length) ?? 0;

  const helpAndSupportUrl = 'https://www.acterio.com/help';

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!!getEcosystemNameFromPath(location.pathname) && existsEcosystem()) {
        setEcosystemName(getEcosystemNameFromPath(location.pathname));
        setCompanies(storedCompanyAndJourneyList);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [location.pathname]);

  useEffect(() => {
    if (!storedCompanyAndJourneyList) {
      setCompanies([]);
      return;
    }
    if (!storedCompanyAndJourneyList.length) {
      setCompanies([]);
      return;
    }

    setCompanies(storedCompanyAndJourneyList);
  }, [seeIfCompanyListChanged, show]);

  function logout() {
    clearAllStorage();
    navigate('/login');
    addAlert({
      type: ALERT_TYPES.SUCCESS,
      title: '👋 See you soon',
      message: 'Logged out successfully',
    });

    clearCurrentTheme();
  }

  function clearCurrentTheme() {
    if (!setCurrentTheme) return;
    setCurrentTheme(defaultSchemes[0]);
  }

  function renderEcosystemProfile() {
    return (
      <Box>
        <Typography
          variant={'h5'}
          marginLeft={'0.5rem'}
          marginBottom={'0.5rem'}
          sx={{ xs: '2.2rem', md: '1.1rem' }}>
          Ecosystem
        </Typography>

        <Typography
          variant={'h6'}
          marginLeft={'0.5rem'}
          marginBottom={'0.5rem'}
          marginTop={'1rem'}
          sx={{
            color: theme.palette.primary.main,
            display: 'flex',
            fontSize: { xs: '1.2rem', md: '0.9rem' },
          }}>
          <Person
            sx={{
              fontSize: { xs: '1.5rem', md: '1.2rem' },
              marginRight: '0.5rem',
            }}
          />
          Profile
        </Typography>

        <Button
          sx={{
            fontSize: { xs: '1.3rem', md: '0.9rem' },
            marginBottom: '0.1rem',
            height: { xs: '5rem', md: 'unset' },
            color: primary.placeholderFont,
            fontWeight: 600,
            width: '100%',
            justifyContent: 'start',
          }}
          onClick={() => {
            const { name } = getEcosystem();
            navigate(`ecosystem/${name}/members/${userId}`);
          }}>
          <Circle
            sx={{
              fontSize: '0.6rem',
              marginRight: '0.5rem',
              color: theme.palette.primary.main,
            }}
          />
          My profile
        </Button>
      </Box>
    );
  }

  function renderCompanyLinks() {
    if (!ecosystemName) return null;
    if (!companies) return null;
    if (!companies.length) return null;

    const companyLinks = companies.map((company: ISavedCompanyInfo, i) => {
      return (
        <Box key={`${company.companyName}+${company.journeyId}`}>
          <Button
            sx={{
              fontSize: { xs: '1.3rem', md: '0.9rem' },
              marginBottom: { xs: '0.8rem', md: '0.1rem' },
              height: { xs: '3rem', md: 'unset' },
              color: primary.placeholderFont,
              fontWeight: 600,
              width: '100%',
              justifyContent: 'start',
            }}
            onClick={() =>
              navigate(
                `/ecosystem/${ecosystemName}/companies/${company.companyName}`,
              )
            }>
            <Circle
              sx={{
                fontSize: '0.6rem',
                marginRight: '0.5rem',
                color: theme.palette.primary.main,
              }}
            />
            {previewText(company.companyName, 24)}
          </Button>

          {!!company.journeyId && (
            <Button
              sx={{
                fontSize: { xs: '1.3rem', md: '0.9rem' },
                marginBottom: { xs: '0.8rem', md: '0.1rem' },
                height: { xs: '3rem', md: 'unset' },
                ml: '1rem',
                width: '100%',
                justifyContent: 'start',
              }}
              onClick={() =>
                navigate(
                  `/ecosystem/${ecosystemName}/companies/${company.companyName}/journey`,
                )
              }>
              <ArrowForwardIos
                sx={{
                  fontSize: '1rem',
                  minHeight: 'unset',
                  marginRight: '0.5rem',
                }}
              />
              {previewText(`Journey of ${company.companyName}`, 20)}
            </Button>
          )}
        </Box>
      );
    });

    return (
      <div>
        <Typography
          variant={'h6'}
          marginLeft={'0.5rem'}
          marginBottom={'0.5rem'}
          marginTop={'1rem'}
          sx={{
            color: theme.palette.primary.main,
            display: 'flex',
            fontSize: { xs: '1.2rem', md: '0.9rem' },
          }}>
          <Business
            sx={{
              fontSize: { xs: '1.5rem', md: '1.2rem' },
              marginRight: '0.5rem',
            }}
          />
          Companies
        </Typography>
        {companyLinks}
      </div>
    );
  }

  function renderAccount() {
    return (
      <Box
        sx={{
          paddingTop: '1rem',
        }}>
        <Typography
          variant={'h5'}
          marginLeft={'0.5rem'}
          marginBottom={'0.5rem'}
          sx={{ xs: '2.2rem', md: '1.1rem' }}>
          Acterio account
        </Typography>
        <Button
          onClick={() => {
            navigate('/account'), clearCurrentTheme();
          }}
          sx={{
            fontSize: { xs: '1.3rem', md: '0.9rem' },
            marginBottom: { xs: '0.8rem', md: '0.1rem' },
            height: { xs: '3rem', md: 'unset' },
            color: primary.placeholderFont,
            fontWeight: 600,
            width: '100%',
            justifyContent: 'start',
            '&:hover': {
              backgroundColor: theme.palette.primary.contrastText,
            },
          }}>
          <Circle
            sx={{
              fontSize: '0.6rem',
              marginRight: '0.5rem',
              color: theme.palette.primary.main,
            }}
          />
          Account settings
        </Button>
        <Button
          onClick={() => navigate('/dashboard')}
          sx={{
            fontSize: { xs: '1.3rem', md: '0.9rem' },
            marginBottom: { xs: '0.8rem', md: '0.1rem' },
            height: { xs: '3rem', md: 'unset' },
            color: primary.placeholderFont,
            fontWeight: 600,
            width: '100%',
            justifyContent: 'start',
            '&:hover': {
              backgroundColor: theme.palette.primary.contrastText,
            },
          }}>
          <Circle
            sx={{
              fontSize: '0.6rem',
              marginRight: '0.5rem',
              color: theme.palette.primary.main,
            }}
          />
          Dashboard
        </Button>
        <Button
          onClick={() => window.open(`${helpAndSupportUrl}`, '_blank')}
          sx={{
            fontSize: { xs: '1.3rem', md: '0.9rem' },
            marginBottom: { xs: '0.8rem', md: '0.1rem' },
            height: { xs: '3rem', md: 'unset' },
            color: primary.placeholderFont,
            fontWeight: 600,
            width: '100%',
            justifyContent: 'start',
            '&:hover': {
              backgroundColor: theme.palette.primary.contrastText,
            },
          }}>
          <Circle
            sx={{
              fontSize: '0.6rem',
              marginRight: '0.5rem',
              color: theme.palette.primary.main,
            }}
          />
          Help & support
        </Button>
      </Box>
    );
  }

  function renderLogout() {
    return (
      <Box>
        <Button
          onClick={logout}
          sx={{
            fontSize: { xs: '1.3rem', md: '0.9rem' },
            marginY: { xs: '0.8rem', md: '1rem' },
            height: { xs: '3rem', md: 'unset' },
            color: primary.placeholderFont,
            fontWeight: 600,
            width: '100%',
            justifyContent: 'start',
            '&:hover': {
              backgroundColor: theme.palette.primary.contrastText,
            },
          }}>
          <LogoutIcon /> Log out
        </Button>
      </Box>
    );
  }

  function renderDivider() {
    return (
      <Divider
        sx={{
          width: '100%',
          margin: 'auto',
          color: primary.primaryDisabledFont,
        }}
      />
    );
  }

  return (
    <Fade in={show}>
      <Box
        sx={{
          zIndex: 1252,
          backgroundColor: 'white',
          borderRadius: '6px',
          border: '1px solid #e0e0e0',
          boxShadow: boxShadow,
          width: { xs: '20rem', md: '18rem' },
          position: 'absolute',
          right: { xs: '0.1rem', md: '-1rem' },
          paddingTop: { xs: '0.5rem', md: '1.25rem' },
          paddingBottom: { xs: '0', md: '0.5rem' },
          paddingX: '1.5rem',
        }}>
        {!inActerio && (
          <>
            {renderEcosystemProfile()}
            {renderDivider()}
            {renderCompanyLinks()}
            {renderDivider()}
          </>
        )}
        {renderAccount()}
        {renderDivider()}
        {renderLogout()}
      </Box>
    </Fade>
  );
};

export default UserMenu;
