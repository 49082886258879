import React, {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { COMPANY_PROFILE_ROUTE, getData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useNavigate, useParams } from 'react-router';
import {
  ICompany,
  ICompanyField,
  ICompanyProfile,
  ICompanyProfileEmployeeDTO,
  ITag,
} from 'utils/models';
import {
  clearCompanyStatus,
  getCompanyEditStatus,
  setCompanyEditStatus,
  storeCompanies,
} from 'utils/storage';
import Loading from 'components/common/Loading';
import useCompanies from 'hooks/Companies/useCompanies';
import { isEmptyRichText } from 'utils/utils';

const useCompany = () => {
  const editStatus = getCompanyEditStatus();
  const { ecosystemName, companyName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const [companyProfile, setCompanyProfile] = useState<ICompanyProfile | null>(
    null,
  );
  const [editProfile, setEditProfile] = useState<boolean>(editStatus);
  const [loading, setLoading] = useState<boolean>(false);
  const [editedName, setEditedName] = useState<string | null>(null);
  const [editedLogo, setEditedLogo] = useState<any | null>(null);

  const [isInfoMissing, setIsInfoMissing] = useState<boolean>(false);

  const { setCompanies } = useCompanies();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ecosystemName || !companyName) return;
    setIsInfoMissing(false);
  }, [companyProfile]);

  useEffect(() => {
    if (!ecosystemName || !companyName) return;
    setLoading(true);

    getData(COMPANY_PROFILE_ROUTE, [
      { name: 'ecosystemName', value: ecosystemName },
      { name: 'companyName', value: encodeURIComponent(companyName) },
    ])
      .then((data) => {
        setCompanyProfile(data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error('error', error);
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: error.message,
        });
        setLoading(false);
      });
    clearCompanyStatus();
  }, [ecosystemName, companyName]);

  useEffect(() => {
    if (editProfile) return;
    setCompanyEditStatus(editProfile);
  }, [editProfile]);

  function updateHeaderAndFirstMainFieldOfCompany(
    updatedCompanyOverview: ICompany,
  ) {
    setCompanies((prev: ICompany[] | null) => {
      if (!prev) return null;
      if (!Array.isArray(prev)) return null;

      const companyIndex = prev.findIndex(
        (company) => company.companyName === updatedCompanyOverview.companyName,
      );

      if (companyIndex === -1) return prev;

      const updatedCompanies = [...prev];
      const companyToUpdate = { ...updatedCompanies[companyIndex] };

      companyToUpdate.companyHeader = updatedCompanyOverview.companyHeader;
      if (updatedCompanyOverview.companyFirstMainField) {
        companyToUpdate.companyFirstMainField =
          updatedCompanyOverview.companyFirstMainField;
      }

      updatedCompanies[companyIndex] = companyToUpdate;
      storeCompanies(updatedCompanies);
      return updatedCompanies;
    });
  }

  function updateCompanyMembers(
    updatedCompanyList: ICompanyProfileEmployeeDTO[],
  ) {
    if (!companyProfile) return;

    const updatedCompanyProfile: ICompanyProfile = {
      ...companyProfile,
      companyProfileEmployeeDto: updatedCompanyList,
    };

    setCompanyProfile(updatedCompanyProfile);
  }

  function updateCompanyField(fieldId: number, value: any) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = { ...prevProfile };
      const allFields = [
        ...updatedProfile.header,
        ...updatedProfile.main,
        ...updatedProfile.footer,
        ...updatedProfile.sidebar,
      ];

      const fieldToUpdate = allFields.find((field) => field.id === fieldId);

      if (fieldToUpdate) {
        setFieldByType(fieldToUpdate, value);
      }

      return updatedProfile;
    });
  }

  function updateCompanyDescription(description: string) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = {
        ...prevProfile,
        company: { ...prevProfile.company, description: description },
      };

      return updatedProfile;
    });
  }

  function setFieldByType(field: ICompanyField, value: any) {
    switch (field.type) {
      case 'TAGS':
        field.selectedTags = value;
        break;
      case 'RICH_TEXT':
        field.richText = value;
        break;
      case 'FILES':
        field.files = value;
        break;
      case 'PEOPLE':
        field.people = value;
        break;
      case 'TEXT':
        field.text = value;
        break;
      case 'DATE':
        field.localDate = value;
        break;
      default:
        break;
    }
  }

  function removeSelectedTag(fieldId: number, tag: ITag) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = { ...prevProfile };
      const allFields = [
        ...updatedProfile.header,
        ...updatedProfile.main,
        ...updatedProfile.footer,
        ...updatedProfile.sidebar,
      ];

      const fieldToUpdate = allFields.find((field) => field.id === fieldId);

      if (fieldToUpdate?.selectedTags) {
        fieldToUpdate.selectedTags = fieldToUpdate.selectedTags.filter(
          (t) => t.id !== tag.id,
        );
      }
      return updatedProfile;
    });
  }

  //   function findFieldById(profile: ICompanyProfile, fieldId: number): ICompanyField | undefined {
  //     const sections = [
  //       profile.header,
  //       profile.main,
  //       profile.sidebar,
  //       profile.footer,
  //     ];

  //     for (const section of sections) {
  //       const field = section.find((f) => f.id === fieldId);
  //       if (field) return field;
  //     }

  //     return undefined;
  //   }

  function updateCompanyName(name: string) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = {
        ...prevProfile,
        company: { ...prevProfile.company, editedName: name },
      };
      return updatedProfile;
    });
    navigate(`/ecosystem/${ecosystemName}/companies/${name}`);
  }

  function updateCompanyLogo(updatedLogo: any) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = { ...prevProfile, logoUrl: updatedLogo };
      return updatedProfile;
    });
  }

  function updateCompanyInfo(fieldName: string, value: string) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile: ICompanyProfile = JSON.parse(
        JSON.stringify(prevProfile),
      ); // Deep copy
      const updatedCompany = updatedProfile.company;

      if (fieldName in updatedCompany) {
        (updatedCompany as any)[fieldName] = value; // Use 'as any' to bypass type checking
      }

      return updatedProfile;
    });
  }

  const getFieldByType = (field: ICompanyField) => {
    switch (field.type) {
      case 'TAGS':
        return field.selectedTags;
      case 'RICH_TEXT':
        return field.richText;
      case 'FILES':
        return field.files;
      case 'PEOPLE':
        return field.people;
      case 'TEXT':
        return field.text;
      case 'DATE':
        return field.localDate;
      default:
        return null;
    }
  };

  function updateMemberRole(
    id: number,
    field: keyof ICompanyProfileEmployeeDTO,
    value: any,
  ) {
    if (companyProfile) {
      const updatedCompanyProfile = { ...companyProfile };
      const updatedMembers =
        updatedCompanyProfile.companyProfileEmployeeDto.map((member) => {
          if (id === member.userId) {
            return {
              ...member,
              [field]: value,
            };
          }
          return member;
        });

      updatedCompanyProfile.companyProfileEmployeeDto = updatedMembers;
      setCompanyProfile(updatedCompanyProfile);
    }
  }

  function checkIfInfoIsMissing(
    field: ICompanyField,
    prop: keyof ICompanyField,
  ) {
    if (!companyProfile) return;

    function returnMissing() {
      switch (prop) {
        case 'files':
          return field.required && !field.files;
        case 'richText':
          return field.required && isEmptyRichText(field.richText);
        case 'selectedTags':
          return (
            (field.required && !field.selectedTags) ||
            (field.selectedTags && field.selectedTags.length < 1)
          );
        default:
          return (
            field.required &&
            (field[prop] === undefined ||
              field[prop] === null ||
              field[prop] === '')
          );
      }
    }

    const isMissing = returnMissing();

    if (!isMissing) return;

    setIsInfoMissing(isMissing);
  }

  function updateCompanyBanner(updatedBanner: string) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;
      const updatedProfile = {
        ...prevProfile,
        company: {
          ...prevProfile.company,
          bannerUrl: updatedBanner,
        },
      };
      return updatedProfile;
    });
  }

  return {
    companyProfile,
    editProfile,
    loading,
    editedLogo,
    editedName,
    isInfoMissing,
    updateMemberRole,
    setEditedName,
    setEditedLogo,
    getFieldByType,
    setCompanyProfile,
    setEditProfile,
    updateCompanyField,
    updateCompanyDescription,
    removeSelectedTag,
    updateCompanyName,
    updateCompanyLogo,
    updateCompanyInfo,
    updateCompanyMembers,
    updateHeaderAndFirstMainFieldOfCompany,
    setIsInfoMissing,
    checkIfInfoIsMissing,
    updateCompanyBanner,
  };
};

const CompanyContext = createContext({} as ReturnType<typeof useCompany>);

export const CompanyProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const companyContext = useCompany();
  const { loading, companyProfile } = companyContext;
  if (loading || !companyProfile) {
    return <Loading />;
  }

  return (
    <CompanyContext.Provider value={companyContext}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => useContext(CompanyContext);
