import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import CompanyEditTeam from './CompanyEditTeam';
import {
  ICompanyField,
  ICompanyProfile,
  ICompanyProfileEmployeeDTO,
} from 'utils/models';
import CompanyEditMain from './CompanyEditMain';
import CompanyEditSave from './CompanyEditSave';
import {
  COMPANY_OVERVIEW,
  COMPANY_UPDATE_INFO_ROUTE,
  COMPANY_UPDATE_PRIV_DATA_ROUTE,
  getData,
  postData,
  putData,
  UPDATE_COMPANY_CONTENT_FIELD,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useNavigate, useParams } from 'react-router';
import { setCompanyEditStatus } from 'utils/storage';
import CompanyEditHeader from './CompanyEditHeader';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { useMobileContext } from 'services/contexts/MobileContext';
import CompanySection from './CompanySection';
import { FlexBox } from 'utils/styledComponents';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import CommonButton from 'components/common/CommonButton';
import { EditOff, KeyboardArrowRight } from '@mui/icons-material';
import { naturalPallete, primary } from 'theme';
import InformationModal from 'components/common/Modal/InformationModal';
import CompanyEditSideSection from './CompanyEditSideSection';

export interface ICompanyEditProfileProps {
  setEditProfile: (editProfile: boolean) => void;
  people: ICompanyProfileEmployeeDTO[];
  canDeleteCompanyProfile: boolean;
}

const CompanyEditProfile: FunctionComponent<ICompanyEditProfileProps> = (
  props,
) => {
  const { isMobile } = useMobileContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { setEditProfile, canDeleteCompanyProfile, people } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const {
    companyProfile,
    editedName,
    editedLogo,
    isInfoMissing,
    updateCompanyName,
    getFieldByType,
    setCompanyProfile,
    updateHeaderAndFirstMainFieldOfCompany,
  } = useCompanyContext();
  const companyName = companyProfile!.company.name;

  const { addAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const theme = useTheme();
  const {
    address = null,
    email = null,
    telephone = null,
    linkedin = null,
    facebook = null,
    twitter = null,
    instagram = null,
    website = null,
  } = companyProfile!.company || {};

  const [copyCompanyProfiles, setCopyCompanyProfiles] =
    useState<ICompanyProfile>(JSON.parse(JSON.stringify(companyProfile)));

  // useEffect(() => {
  //   if (companyName !== companyFields?.companyInfo?.name || !companyFields) {
  //     setCompanyFields(props);
  //   }
  // }, []);

  useEffect(() => {
    if (companyProfile!.company.name !== undefined) {
      updateCompanyName(companyName);
    }
  }, [companyProfile!.company.name]);

  function isStringEmpty(htmlString: string): boolean {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    return !tempElement.textContent?.trim();
  }

  async function saveCompanyFields(companyFields: ICompanyField[]) {
    setLoading(true);
    let requiredFieldsFilled = true;
    try {
      const updateRequests = [];
      for (const field of companyFields) {
        const jsonStringContent = getFieldByType(field);
        const companyProfileFieldId = field.id;
        if (
          (!jsonStringContent && field.required) ||
          (isStringEmpty(jsonStringContent as string) && field.required)
        ) {
          requiredFieldsFilled = false;
          addAlert({
            type: ALERT_TYPES.ERROR,
            message: field.name + ' is a required field.',
          });
          continue;
        }

        if (!field.id) continue;

        const queryParams = [
          { name: 'companyName', value: encodeURIComponent(companyName) },
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'companyProfileFieldId', value: companyProfileFieldId },
        ];

        const updateRequest = putData(
          UPDATE_COMPANY_CONTENT_FIELD,
          queryParams,
          {
            jsonStringContent,
          },
        );
        updateRequests.push(updateRequest);
      }

      await Promise.all(updateRequests);

      const updatedCompanyOverview = await getData(COMPANY_OVERVIEW, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
        { name: 'companyName', value: companyName },
      ]);

      setCompanyProfile((prev) => {
        if (prev === null) return null;
        return {
          ...prev,
          header: updatedCompanyOverview.companyHeader,
        };
      });

      updateHeaderAndFirstMainFieldOfCompany(updatedCompanyOverview);
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    } finally {
      setLoading(false);
      if (requiredFieldsFilled) setEditProfile(false);
    }
  }

  async function saveCompanyProfile() {
    const allFields = [
      ...companyProfile!.header,
      ...companyProfile!.main,
      ...companyProfile!.sidebar,
      ...companyProfile!.footer,
    ];

    await updateContacts();
    updatePrivateData();
    await saveCompanyFields(allFields).then(async () => {
      if (
        companyProfile!.company.name !== editedName ||
        companyProfile!.company.logoUrl !== editedLogo
      ) {
        await updateInfo();
      }
    });
  }

  async function updateInfo() {
    setLoading(true);

    const formData = new FormData();
    const updateParams: { name: string; value: string }[] = [];

    if (editedLogo) {
      formData.append('companyLogo', editedLogo, 'logo.png');
    }
    if (editedName) {
      updateParams.push({
        name: 'newCompanyName',
        value: encodeURIComponent(editedName),
      });
    }

    const shouldUpdate = updateParams.length > 0 || editedLogo !== null;

    try {
      if (shouldUpdate) {
        await postData(
          COMPANY_UPDATE_INFO_ROUTE,
          [
            { name: 'companyName', value: encodeURIComponent(companyName) },
            { name: 'ecosystemName', value: ecosystemName },
            ...updateParams,
          ],
          formData,
        );

        if (editedName) {
          navigate(`/ecosystem/${ecosystemName}/companies/${editedName}`);
        }

        setCompanyEditStatus(false);
        window.location.reload();
      } else {
        setLoading(false);
      }
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  async function updateContacts() {
    try {
      const data = await postData(COMPANY_UPDATE_INFO_ROUTE, [
        {
          name: 'twitter',
          value: twitter ?? '',
        },
        {
          name: 'linkedin',
          value: linkedin ?? '',
        },
        {
          name: 'facebook',
          value: facebook ?? '',
        },
        {
          name: 'instagram',
          value: instagram ?? '',
        },
        {
          name: 'website',
          value: website ?? '',
        },
        {
          name: 'email',
          value: email ?? '',
        },
        {
          name: 'number',
          value: telephone ?? '',
        },
        {
          name: 'address',
          value: address ?? '',
        },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      if (!data) return;

      setCompanyProfile((prevProfile) => {
        if (!prevProfile) return null;
        return { ...prevProfile, company: data };
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  async function updatePrivateData() {
    try {
      const userIds: number[] = companyProfile!.companyProfileEmployeeDto.map(
        (employee) => employee.userId,
      );
      const userRoles: string[] = companyProfile!.companyProfileEmployeeDto.map(
        (employee) => employee.companyRole,
      );

      const data = await postData(COMPANY_UPDATE_PRIV_DATA_ROUTE, [
        {
          name: 'userIDs',
          value: userIds,
        },
        {
          name: 'userRoles',
          value: userRoles,
        },
        {
          name: 'description',
          value: companyProfile!.company.description ?? '',
        },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setCompanyProfile((prevProfile) => {
        if (!prevProfile) return null;
        return { ...prevProfile, company: data };
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function formatStr(str: string) {
    return str.replaceAll('_', ' ');
  }

  function renderPeoples() {
    return people.slice(0, 3).map((people) => (
      <Grid
        item
        key={people.userId}
        onClick={() =>
          navigate(`/ecosystem/${ecosystemName}/members/${people.userId}`)
        }>
        <FlexBox sx={{ mb: '1rem' }}>
          <Box sx={{ mr: '1rem', height: '3rem' }}>
            <img
              src={people.profPicUrl}
              style={{
                height: '3rem',
                width: '3rem',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            />
          </Box>
          <Box sx={{ justifyItems: 'flex-start' }}>
            <FlexBox>
              <Typography variant={'h6'}>{people.name}</Typography>
              <AccessLevelTagCard
                accessLevelName={people.accessLevelName || 'Default'}
                accessLevelColor={people.accessLevelColor}
                left={'1rem'}
                height={'1.3rem'}
              />
            </FlexBox>
            <Typography variant={'body1'}>{people.companyRole}</Typography>
          </Box>
        </FlexBox>
      </Grid>
    ));
  }

  function renderAddRemoveTeamButton() {
    return (
      <FlexBox className='end'>
        <CommonButton
          text='Add/remove'
          onClick={openModal}
          bgcolor='none'
          color={naturalPallete.mainBlackFont}
          endIcon={<KeyboardArrowRight />}
          sx={{
            width: '10rem',
            padding: '0rem',
            '&:hover': {
              backgroundColor: naturalPallete,
            },
          }}
        />
      </FlexBox>
    );
  }

  function renderAddRemoveTeamModal() {
    return (
      <InformationModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        primaryText='Save'
        primaryOnClick={() => setIsModalOpen(false)}
        // secondaryText='Revert changes'
        // secondaryOnClick={() => console.log('revert')}
        headerText={'Add a team member'}>
        <Box
          sx={{
            overflowX: 'hidden',
          }}>
          <CompanyEditTeam companyName={companyName} members={people} />
        </Box>
      </InformationModal>
    );
  }

  function revertChanges() {
    if (!companyProfile) return;
    setCompanyProfile({
      ...copyCompanyProfiles,
      company: {
        ...copyCompanyProfiles.company,
        bannerUrl: companyProfile.company.bannerUrl,
      },
    });

    setEditProfile(false);
  }

  function renderCancelAndSaveButton() {
    return (
      <FlexBox
        sx={{
          mt: { xs: '1.5rem', lg: '0rem' },
          mb: '1rem',
          justifyContent: { xs: 'end', lg: 'center' },
        }}>
        <CommonButton
          text='Cancel'
          onClick={revertChanges}
          bgcolor={theme.palette.primary.light}
          color={naturalPallete.mainBlackFont}
          sx={{
            marginRight: '1rem',
            paddingY: '1.2rem',
            '&:hover': {
              bgcolor: theme.palette.primary.main,
              color: primary.pureWhite,
            },
          }}
          icon={<EditOff />}
        />
        <CompanyEditSave
          loading={loading}
          onClick={() => saveCompanyProfile()}
          disabled={isInfoMissing}
        />
      </FlexBox>
    );
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={8}>
        <CompanyEditHeader
          companyName={companyName}
          canDeleteCompanyProfile
          description={companyProfile?.company.description ?? ''}
        />
        <CompanyEditMain />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', lg: 'column' },
          }}>
          {renderCancelAndSaveButton()}
          <Box>
            <CompanySection title={'Team'}>
              <>
                <Box sx={{ padding: '2rem 1rem 1rem 1.5rem' }}>
                  {renderPeoples()}
                  {renderAddRemoveTeamButton()}
                </Box>
                {renderAddRemoveTeamModal()}
              </>
            </CompanySection>
            <CompanyEditSideSection />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default CompanyEditProfile;
