import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import InputField from 'components/common/InputField';
import { useParams } from 'react-router';
import {
  COMPANY_REGISTER_NEW,
  postData,
  COMPANY_TEMPLATE_NAME_ID,
  getData,
} from 'utils/requests';
import { useNavigate } from 'react-router-dom';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import SelectDropdown from 'components/common/SelectDropdown';
import {
  setCompanyEditStatus,
  getStoredCompanies,
  storeCompanies,
} from 'utils/storage';
import { FlexBox } from 'utils/styledComponents';
import CommonButton, { ButtonSize } from 'components/common/CommonButton';
import { naturalPallete } from 'theme';

interface INewCompany {
  closed: boolean;
  setAddNewCompany: (value: boolean) => void;
}

interface INameId {
  name: string;
  id: number;
}

const NewCompany: FunctionComponent<INewCompany> = (props) => {
  const { closed, setAddNewCompany } = props;
  const { ecosystemName } = useParams();
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();
  const [logo, setLogo] = useState<any>(null);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [companyProfileTemplateName, setCompanyProfileTemplateName] =
    useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<INameId[]>([]);
  const [loadedOptions, setLoadedOptions] = useState<boolean>(false);

  const disabled = !name || !email || !role;

  useEffect(() => {
    if (closed) reset();
  }, [closed]);

  useEffect(() => {
    fetchCompanyTemplate();
  }, [loadedOptions]);

  async function fetchCompanyTemplate() {
    try {
      const response = await getCompanyTemplate();
      setOptions(response);
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    } finally {
      setLoadedOptions(true);
      if (options.length > 0) {
        setCompanyProfileTemplateName(options[0].name);
      }
    }
  }

  async function createCompany() {
    setLoading(true);

    let formData: FormData | undefined = new FormData();
    if (logo) {
      formData.append('companyLogo', logo, 'logo.png');
    } else {
      formData = undefined;
    }

    try {
      //find id from options array with name
      const companyProfileTemplateId = options.find(
        (option: INameId) => option.name === companyProfileTemplateName,
      )?.id;

      const response = await postData(
        COMPANY_REGISTER_NEW,
        [
          { name: 'companyName', value: encodeURIComponent(name) },
          { name: 'companyRole', value: role },
          { name: 'email', value: email },
          { name: 'tlf', value: phone ?? null },
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'companyProfileTemplateId', value: companyProfileTemplateId },
        ],
        formData,
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Company ${name} created`,
      });

      const savedCompanies = getStoredCompanies();
      storeCompanies(savedCompanies ? [...savedCompanies, response] : response);
      setCompanyEditStatus(true);
      navigate(name);
    } catch (e: any) {
      console.error('error', e);
      setLoading(false);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  async function getCompanyTemplate() {
    try {
      const response = await getData(COMPANY_TEMPLATE_NAME_ID, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      return response;
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function companyTemplateDropdown() {
    if (!loadedOptions) return <div>Loading...</div>;
    return (
      <Box sx={{ mb: '1rem' }}>
        <FlexBox sx={{ mb: '0.5rem' }}>
          <Typography variant='h6' sx={{ mr: '0.5rem' }}>
            Select a company profile template*
          </Typography>
          <em>(This can't be changed later)</em>
        </FlexBox>

        <SelectDropdown
          value={companyProfileTemplateName}
          onChange={setCompanyProfileTemplateName}
          options={options.map((option) => option.name)}
          getOptionLabel={(option) => option}
          disabled={loading}
          sx={{
            width: '100%',
            boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.12)',
          }}
        />
      </Box>
    );
  }

  function reset() {
    setLogo(null);
    setName('');
    setRole('');
    setPhone('');
    setCompanyProfileTemplateName(options[0].name);
  }

  function renderInputs() {
    return (
      <Box>
        {companyTemplateDropdown()}
        <Box sx={{ mb: '1rem' }}>
          <Typography variant={'h6'} sx={{ mb: '0.5rem' }}>
            Company name*
          </Typography>
          <InputField
            value={name}
            placeholder={'Company name'}
            readonly={loading}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setName(event.target.value)
            }
          />
        </Box>
        <Box sx={{ mb: '1rem' }}>
          <Typography variant={'h6'} sx={{ mb: '0.5rem' }}>
            Company email*
          </Typography>
          <InputField
            value={email}
            placeholder={'Company email'}
            readonly={loading}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEmail(event.target.value)
            }
          />
        </Box>
        <Box sx={{ mb: '1rem' }}>
          <Typography variant={'h6'} sx={{ mb: '0.5rem' }}>
            Your role in the company*
          </Typography>
          <InputField
            readonly={loading}
            value={role}
            placeholder={'Your role in the company'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setRole(event.target.value)
            }
          />
        </Box>
        <Box sx={{ mb: '1rem' }}>
          <Typography variant={'h6'} sx={{ mb: '0.5rem' }}>
            Phone number
          </Typography>
          <InputField
            readonly={loading}
            value={phone}
            placeholder={'Phone number'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setPhone(event.target.value)
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <>
      {renderInputs()}

      <FlexBox sx={{ justifyContent: 'flex-end', mt: '2rem', gap: '0.5rem' }}>
        <CommonButton
          text='Cancel'
          onClick={() => setAddNewCompany(false)}
          sx={{
            width: '10rem',
            paddingY: '1.2rem',
            bgcolor: 'none',
            color: naturalPallete.mainBlackFont,
            '&:hover': {
              bgcolor: theme.palette.primary.light,
            },
          }}
        />
        <CommonButton
          text='Create'
          onClick={createCompany}
          isDisabled={disabled}
          size={ButtonSize.SMALL}
          sx={{
            width: '10rem',
            paddingY: '1.2rem',
            '&:hover': {
              bgcolor: theme.palette.primary.main,
            },
          }}
        />
      </FlexBox>
    </>
  );
};

export default NewCompany;
