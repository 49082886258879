import { useContext, useEffect, useState } from 'react';
import { getData, JOURNEY_COMPANY_PROFILE_ROUTE } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { IJourney } from 'utils/models';

const useJourney = () => {
  const { ecosystemName, companyName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [journey, setJourney] = useState<IJourney | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchJourney();
  }, [companyName]);

  async function fetchJourney() {
    setLoading(true);
    if (!companyName) return;

    try {
      const data = await getData(JOURNEY_COMPANY_PROFILE_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyName', value: encodeURIComponent(companyName) },
      ]);

      setJourney(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return {
    loading,
    journey,
  };
};

export default useJourney;
