import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { validateColor } from 'utils/utils';
import { acterioTheme, primary, white } from 'theme';

export interface IAccessLevelTagCardProps {
  accessLevelName: string;
  accessLevelColor?: string;
  height?: string | number;
  top?: string | number;
  left?: string | number;
  backgroundColor?: string;
  sx?: SxProps;
  borderRadius?: string | number;
}

const AccessLevelTagCard: React.FC<IAccessLevelTagCardProps> = (props) => {
  const {
    accessLevelName,
    accessLevelColor,
    height,
    top,
    left,
    backgroundColor,
    sx,
    borderRadius,
  } = props;
  const bgColor = validateColor(accessLevelColor, acterioTheme.light);

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '10px 7px 7px 10px',
        border: 'none',
        top: top ?? 0,
        left: left ?? 0,
        width: 'max-content',
        height: height ?? '2rem',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        padding: height
          ? `calc(${height} / 5) calc(${height} / 7) calc(${height} / 5) calc(${height} / 1.2) `
          : '0.938rem 0.75rem 0.938rem 2rem',
        backgroundColor: backgroundColor ?? primary.dark,
        ...sx,
      }}>
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          top: '0',
          left: '0',
          width: height ?? '2rem',
          height: height ?? '2rem',
          borderRadius: borderRadius ?? '7px 0 0 7px',
          backgroundColor: bgColor,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          paddingX: '1rem',
        }}>
        <Typography
          variant={'h6'}
          sx={{
            color: white,
            fontSize: `calc(${height} / 2)` ?? '1rem',
          }}>
          {accessLevelName}
        </Typography>
      </Box>
    </Box>
  );
};

export default AccessLevelTagCard;
