import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography, useTheme } from '@mui/material';
import CompanySection from './CompanySection';
import InputField from '../common/InputField';
import MainButton, { ButtonType } from '../common/MainButton';
import { COMPANY_DELETE_NOTE_ROUTE, deleteData } from 'utils/requests';
import { useParams } from 'react-router';
import { ICompanySharedNote } from 'utils/models';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import Modal from '../common/Modal';
import RichTextEditor from '../common/RichTextEditor';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '275px',
  },
  btn: {
    height: '30px !important',
    width: '30px !important',
    minWidth: '30px !important',
    marginRight: '-10px !important',

    '& svg': {
      height: 22,
      width: 22,
      color: theme.palette.primary.dark,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: '5rem',

    '& p': {
      marginRight: '1rem !important',
      color: '#757575 !important',
      fontSize: '0.7rem !important',
    },

    '& button': {
      height: '35px !important',
      width: '85px !important',
      minWidth: '85px !important',
      margin: '0 !important',
      marginRight: '1rem !important',
    },
  },
  footerCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface ICompanyNewNote {
  saveNote: (t: string, c: string) => Promise<void>;
  companyName: string;
  note: ICompanySharedNote;
  setNote: React.Dispatch<React.SetStateAction<ICompanySharedNote>>;
  updateNoteDisplay: (
    n: ICompanySharedNote,
    updated?: boolean,
    deleted?: boolean,
  ) => void;
  saveLoading: boolean;
}

const CompanyEditNote: FunctionComponent<ICompanyNewNote> = (props) => {
  const {
    companyName,
    note,
    setNote,
    updateNoteDisplay,
    saveNote,
    saveLoading,
  } = props;
  const { ecosystemName } = useParams();
  const classes = useStyles();

  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);

  const [title, setTitle] = useState<string>(note.noteTitle);
  const [content, setContent] = useState<string>(note.noteContent);
  const [lastEditedAt, setLastEditedAt] = useState<string>('');
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  useEffect(() => {
    setTitle(note.noteTitle);
    setContent(note.noteContent);
  }, [note.id]);

  function handleTitleChange(e: any) {
    const newTitle = e;
    setTitle(newTitle);
    setNote((prevNote) => ({
      ...prevNote,
      noteTitle: newTitle,
    }));
  }

  function handleContentChange(e: any) {
    const newContent = e;
    setContent(newContent);
    setNote((prevNote) => ({
      ...prevNote,
      noteContent: newContent,
    }));
  }

  function handleOnBlur() {
    saveNote(title, content);
  }

  async function deleteNote() {
    if (!note) return;
    setLoadingDelete(true);
    try {
      await deleteData(COMPANY_DELETE_NOTE_ROUTE, [
        {
          name: 'noteId',
          value: note?.id,
        },
        {
          name: 'companyName',
          value: encodeURIComponent(companyName),
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Note deleted',
      });
      updateNoteDisplay(note, true, true);
      setConfirmDelete(false);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoadingDelete(false);
  }

  return (
    <>
      <CompanySection title={'Shared Notes'} shadow={true}>
        <div className={classes.root}>
          <InputField
            key={'note-title'}
            value={title}
            onChange={(e) => handleTitleChange(e.target.value)}
            placeholder={'Unnamed note'}
            onBlur={handleOnBlur}
          />
          <RichTextEditor
            value={content}
            onChange={(e) => handleContentChange(e)}
            onBlur={handleOnBlur}
            height={230}
          />
          <div className={classes.footer}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <MainButton
                text={'Save'}
                type={ButtonType.FILLED}
                loading={saveLoading}
                onClick={handleOnBlur}
                backgroundColor={theme.palette.primary.dark}
              />
              <MainButton
                text={'Delete'}
                type={ButtonType.FILLED}
                loading={loadingDelete}
                onClick={() => setConfirmDelete(true)}
                backgroundColor={'#EF5350'}
              />
            </div>
          </div>
        </div>
      </CompanySection>
      <Modal
        title={'Are you sure you want to delete this Note?'}
        open={confirmDelete}
        close={() => setConfirmDelete(false)}
        showFooter={true}
        cancelButtonText={'No'}
        saveButtonText={'Yes'}
        headerColor={'#FFCDD2'}
        saveButtonColor={'#EF5350'}
        saveAction={() => deleteNote()}
        largeFooter={true}
        loading={loadingDelete}
        hideFooterBorder={true}>
        <Typography variant={'body2'}>
          This action is irreversible and you will not be able to recover this
          Note once deleted
        </Typography>
      </Modal>
    </>
  );
};

export default CompanyEditNote;
