import { Button, Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { primary } from 'theme';
import {
  IApplicantAssessment,
  IAssessmentApplicantListItem,
} from 'utils/models';
import AssessmentForm from './AssessmentForm';
import { APPLICATION_ASSESSMENTS_APPLICANT, getData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import ApplicantInfo from '../ApplicantInfo';
import Loading from 'components/common/Loading';
import CompleteCheck from 'components/common/CompleteCheck';
import InformationModal from 'components/common/Modal/InformationModal';

interface IAssessmentOfTheApplicantProps {
  listOfApplicants: IAssessmentApplicantListItem[];
  setListOfApplicants: Dispatch<SetStateAction<IAssessmentApplicantListItem[]>>;
  email: string;
  assessorName: string;
  indexOfSelectedApplicant: number;
  setIndexOfSelectedApplicant: Dispatch<SetStateAction<number>>;
}

const AssessmentPage: FunctionComponent<IAssessmentOfTheApplicantProps> = (
  props,
) => {
  const {
    listOfApplicants,
    setListOfApplicants,
    email,
    assessorName,
    indexOfSelectedApplicant,
    setIndexOfSelectedApplicant,
  } = props;

  const theme = useTheme();
  const { addAlert } = useContext(AlertContext);
  const { hashedId } = useParams();

  const [applicantAssessment, setApplicantAssessment] =
    useState<IApplicantAssessment | null>(null);

  const [applicantToRender, setApplicantToRender] =
    useState<IAssessmentApplicantListItem>(
      listOfApplicants[indexOfSelectedApplicant],
    );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setApplicantToRender(listOfApplicants[indexOfSelectedApplicant]);
  }, [indexOfSelectedApplicant]);

  useEffect(() => {
    if (!applicantToRender || !applicantToRender.id) return;
    fetchApplicantAssessment(applicantToRender.id);
  }, [applicantToRender]);

  async function fetchApplicantAssessment(applicantToRenderId: number) {
    setIsLoading(true);

    try {
      const data = await getData(
        APPLICATION_ASSESSMENTS_APPLICANT,
        [
          {
            name: 'hashedId',
            value: hashedId,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'applicantId',
            value: applicantToRenderId,
          },
        ],
        true,
      );

      setApplicantAssessment(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderApplicantInfo() {
    if (isLoading) return <Loading />;

    if (!applicantAssessment || !applicantToRender)
      return <Typography variant='body1'>Select an application</Typography>;

    return (
      <ApplicantInfo
        sections={applicantAssessment.applicantDetails.sections}
        applicant={applicantAssessment.applicantDetails.applicant}
      />
    );
  }

  function renderAssessmentForm() {
    if (isLoading) return <Loading />;

    if (!applicantAssessment || !applicantToRender)
      return <Typography variant='body1'>Select an application</Typography>;

    return (
      <AssessmentForm
        assessment={applicantAssessment.assessmentTemplate}
        setApplicantAssessment={setApplicantAssessment}
        assessmentNumber={indexOfSelectedApplicant + 1}
        hashedId={hashedId}
        applicantId={applicantToRender.id}
        email={email}
        assessorName={assessorName}
        setListOfApplicants={setListOfApplicants}
        openSuccessModal={openSuccessModal}
      />
    );
  }

  function renderEndOfAssessmentModal() {
    if (!isModalOpen) return;

    return (
      <InformationModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        headerText='Assessment finished'
        primaryText='Stay on this page'
        primaryOnClick={() => setIsModalOpen(false)}
        headerSize='1.875rem'
        width='36rem'
        buttonSx={{
          '&:hover': {
            color: '#13253E',
            backgroundColor: '#EBF1F9',
          },
        }}>
        {isLoading ? (
          <Loading height={200} />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 'bold', mb: '0.75rem' }}>
              Assessment finished!
            </Typography>
            <Typography>
              All of your answers were submitted and saved. You can safely close
              the tab now, or stay and adjust your answers.
            </Typography>
          </Box>
        )}
      </InformationModal>
    );
  }

  function openSuccessModal() {
    setIsModalOpen(true);
  }

  function handleSwapApplicant(index: number) {
    // handleSaveProgress();
    setIndexOfSelectedApplicant(index);
  }

  // async function handleSaveProgress() {
  //   if (!applicantAssessment || !applicantToRender || !applicantToRender.id)
  //     return;

  //   try {
  //     const data = await putData(
  //       APPLICATION_ASSESSMENTS_APPLICANT,
  //       [
  //         { name: 'hashedId', value: hashedId },
  //         { name: 'applicantId', value: applicantToRender.id },
  //         { name: 'email', value: email },
  //         {
  //           name: 'assessorName',
  //           value: assessorName,
  //         },
  //       ],
  //       applicantAssessment.assessmentTemplate,
  //     );

  //     // addAlert({
  //     //   type: ALERT_TYPES.SUCCESS,
  //     //   message: 'Progress saved',
  //     // });
  //   } catch (e: any) {
  //     console.error('error', e);
  //     addAlert({
  //       type: ALERT_TYPES.ERROR,
  //       message: e.message,
  //     });
  //   }
  // }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '2rem',
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: '2rem' }}>
        <Typography variant='h3'>
          {applicantToRender
            ? applicantToRender.name
            : listOfApplicants[0].name}
        </Typography>

        <Typography variant='h4' sx={{ color: primary.natural3, ml: '1rem' }}>
          {applicantToRender
            ? applicantToRender.status
            : listOfApplicants[0].status}
        </Typography>
      </Box>

      <Box sx={{ mb: '2rem', alignItems: 'center' }}>
        {listOfApplicants.map((item, index) => {
          const isActive = indexOfSelectedApplicant === index;
          return (
            <Button
              key={item.id}
              onClick={() => handleSwapApplicant(index)}
              sx={{
                borderRadius: '100%',
                minWidth: 'unset',
                minHeight: 'unset',
                width: '3rem',
                aspectRatio: 1,
                border: `1px solid ${primary.additionalDarkGreen}`,
                padding: 0,
                bgcolor: isActive ? primary.additionalDarkGreen : 'inherit',
                color: isActive
                  ? primary.pureWhite
                  : primary.additionalDarkGreen,
                mr: '0.5rem',

                ':hover': {
                  bgcolor: primary.additionalGreen,
                  color: primary.additionalDarkGreen,
                },
              }}>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  color: 'inherit',
                  margin: 0,
                }}>
                {index + 1}
              </Typography>
              {item.isSubmitted && (
                <CompleteCheck
                  iconSize={'1rem'}
                  sx={{
                    border: '1px solid white',
                    position: 'absolute',
                    right: '-0.5rem',
                    bottom: '-0.5rem',
                  }}
                />
              )}
            </Button>
          );
        })}
      </Box>

      <Grid container columnSpacing={'0.25rem'}>
        <Grid item xs={7}>
          <Box
            sx={{
              borderRadius: 2,
              padding: '2rem',
              bgcolor: primary.additionalLightGreen,
              boxShadow: theme.shadows[2],
              border: `1px solid ${primary.greyBorder}`,
            }}>
            {renderAssessmentForm()}
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              borderRadius: 2,
              padding: '2rem',
              bgcolor: primary.pureWhite,
              boxShadow: theme.shadows[2],
              border: `1px solid ${primary.greyBorder}`,
            }}>
            {renderApplicantInfo()}
          </Box>
        </Grid>
      </Grid>
      {renderEndOfAssessmentModal()}
    </Box>
  );
};

export default AssessmentPage;
