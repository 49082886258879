import { useContext, useState } from 'react';
import { APPLICATIONS_APPLY, postFormData } from 'utils/requests';
import { FileWithId } from '../../components/Admin/Applications/FormWithHook';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';

interface IPostForm {
  formId: number | string | undefined;
  //filledForm: Partial<IApplicationForm> | null;
}

const usePostForm = (props: IPostForm) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const { addAlert } = useContext(AlertContext);

  const { formId } = props;

  async function submitForm(applicationForm: any, files: FileWithId[] | null) {
    if (!formId || !applicationForm) {
      console.log('Something went wrong');
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('applicationFormDto', JSON.stringify(applicationForm));

      files?.forEach((fileWithId) => {
        formData.append(`${fileWithId.questionId}`, fileWithId.file);
      });

      const data = await postFormData(APPLICATIONS_APPLY, formData, true);
      setIsSuccess(true);
    } catch (e: any) {
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: e.message,
        });
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  }

  return { submitForm, isLoading, isSuccess, setIsSuccess };
};

export default usePostForm;
