import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography, useTheme } from '@mui/material';
import CollapseList from '../common/CollapseList';
import NotesIcon from '@mui/icons-material/Notes';
import Modal from '../common/Modal';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ICompanySharedNote } from 'utils/models';
import { MAX_NUM_LIST_ITEMS } from './CompanyPrivateProfile';
import { getTextFromHTML, previewText } from 'utils/utils';
import { wait } from '@testing-library/user-event/dist/utils';
import RichTextDisplay from '../common/RichTextDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
    border: '1px solid #D4D4D4',
    borderRadius: '4px',
    padding: '5px 10px',
    marginBottom: '7.5px',
    cursor: 'pointer',
    maxWidth: '285px',
    overflowWrap: 'anywhere',
    maxHeight: '59.3px',
  },
  smallNote: {
    width: '16.875rem',
    marginLeft: '5px',
    marginRight: '5px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& h6': {
      fontSize: '0.8rem',
    },

    '& p': {
      fontSize: '0.7rem',
    },
  },
  main: {
    marginTop: '10px',

    '& p': {
      fontSize: '0.7rem',
    },
  },
  allNotes: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    maxHeight: '18.75rem',
    minHeight: '17.5rem',
    overflowY: 'auto',
    padding: '16px',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',

    '& button': {
      borderRadius: '4px',
      marginBottom: '10px',
      fontSize: '0.65rem !important',
      color: `${theme.palette.primary.dark} !important`,
      padding: '5px',
      fontWeight: 800,

      '& svg': {
        height: '20px',
        width: '20px',
        color: `${theme.palette.primary.dark} !important`,
        marginRight: '3px',
        marginTop: '-1px',
      },
    },
  },
  lastEdited: {
    color: `${theme.palette.primary.dark} !important`,
    fontSize: '0.7rem !important',
    marginLeft: '1.5px !important',
  },
  selected: {
    backgroundColor: '#EEEEEE',
  },
  overflow: {
    overflow: 'hidden',
  },
}));

export interface IEditedNote {
  id?: number;
  noteTitle: string;
  noteContent: string;
  noteTag: string | null;
}

interface ICompanySharedNotes {
  sharedNotes: ICompanySharedNote[];
  setNote: (note: ICompanySharedNote) => void;
  setShowNote: (show: boolean) => void;
  canEditCompanyProfile: boolean;
  showNote: boolean;
  patchingNote: boolean;
}

const CompanySharedNotes: FunctionComponent<ICompanySharedNotes> = (props) => {
  const {
    sharedNotes,
    setNote,
    setShowNote,
    canEditCompanyProfile,
    showNote,
    patchingNote,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [notes, setNotes] = useState<ICompanySharedNote[]>(sharedNotes);
  const [seeAllNotes, setSeeAllNotes] = useState<boolean>(false);
  const notesPreview = [...notes].splice(0, MAX_NUM_LIST_ITEMS);
  const seeMore = notes.length > MAX_NUM_LIST_ITEMS;

  useEffect(() => {
    if (!showNote) {
      setSelectedIndex(null);
    }
  }, [showNote]);

  useEffect(() => {
    setNotes(sharedNotes);
    if (sharedNotes.length !== notes.length) {
      setSelectedIndex(0);
    }
  }, [sharedNotes]);

  function renderNotes(all?: boolean) {
    const _notes = all ? notes : seeMore ? notesPreview : notes;

    return _notes.map((note, i) => {
      const { id, noteTitle, noteContent } = note;

      return (
        <Fade in={true} key={`note-${id}-index-${i}`}>
          <div
            onClick={() => {
              wait(500).then(() => {
                setShowNote(true);
                setSelectedIndex(i);
                if (seeAllNotes) {
                  setSeeAllNotes(false);
                }
                setNote(note);
              });
            }}
            className={clsx(classes.root, classes.overflow, {
              [classes.smallNote]: all,
              [classes.selected]: selectedIndex !== null && selectedIndex === i,
            })}>
            <div className={classes.header}>
              <Typography variant={'h6'} style={{ width: 'max-content' }}>
                {all ? noteTitle : previewText(noteTitle, 40)}
              </Typography>
            </div>
            <div className={classes.main}>
              <Typography variant={'body1'}>
                <RichTextDisplay
                  overrideQuill
                  text={
                    all
                      ? noteContent
                      : previewText(getTextFromHTML(noteContent), 50)
                  }
                />
              </Typography>
            </div>
          </div>
        </Fade>
      );
    });
  }

  function renderAction() {
    if (!canEditCompanyProfile) return undefined;

    return (
      <Button
        onClick={() => {
          setNote({
            id: -1,
            noteTitle: '',
            noteContent: '',
            noteTag: '',
            dateCreated: '',
            simplifiedDate: '',
          });
          setShowNote(true);
        }}>
        <AddCircleOutlineIcon /> New Note
      </Button>
    );
  }

  return (
    <>
      <CollapseList
        defaultExpanded={true}
        title={'Shared Notes'}
        color={theme.palette.primary.main}
        icon={<NotesIcon />}
        action={renderAction()}
        seeMore={seeMore}
        seeMoreAction={() => setSeeAllNotes(true)}>
        {<div>{renderNotes()}</div>}
      </CollapseList>
      <Modal
        open={seeAllNotes}
        close={() => setSeeAllNotes(false)}
        title={'Shared Notes'}
        color={theme.palette.primary.main}
        hideFooterBorder={true}
        noPadding={true}
        largeFooter={true}>
        <div className={classes.allNotes}>{renderNotes(true)}</div>
      </Modal>
    </>
  );
};

export default CompanySharedNotes;
