import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Grid, Theme, Typography } from '@mui/material';
import {
  // CompanyAboutUs,
  ICompanyAboutUs,
} from './CompanyAbousUs';
import CompanyPeople, { IPeople } from './CompanyPeople';
import CompanyWorkspace from './CompanyWorkSpace';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import CompanyKPI from './CompanyKPI';
import CompanyGoals from './CompanyGoals';
import CompanyAdminDocuments from './CompanyAdminDocuments';
import CompanyResourceLinks from './CompanyResourceLinks';
import CompanySection from './CompanySection';
import CompanyProblemAndMarket, {
  IProblemAndMarket,
} from './CompanyProblemAndMarket';
import {
  COMPANY_NEW_NOTE_ROUTE,
  COMPANY_PROFILE_PRIVATE_ROUTE,
  COMPANY_UPDATE_NOTE_ROUTE,
  getData,
  patchData,
  postData,
} from 'utils/requests';
import { useParams } from 'react-router';
import Loading from '../common/Loading';
import {
  ICompanyDoc,
  ICompanyGoal,
  ICompanyKPI,
  ICompanyPrivateProfileInfo,
  ICompanySharedNote,
} from 'utils/models';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useNavigate } from 'react-router-dom';
import MainButton, { ButtonType } from '../common/MainButton';
import CompanyEditNote from './CompanyEditNote';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  menuOption: {
    backgroundColor: 'white !important',
    padding: '8px 15px !important',
    margin: '3px 0 !important',
    borderRadius: '4px !important',
    fontWeight: '800',
    width: '100% !important',
    justifyContent: 'flex-start !important',
    border: '1px solid #e0e0e0 !important',

    '& h6': {
      fontSize: '0.8rem !important',
    },
  },
  activeMenuOption: {
    backgroundColor: `${theme.palette.primary.dark} !important`,
    border: `1px solid ${theme.palette.primary.dark} !important`,
    '& h6': {
      color: 'white !important',
    },
  },
  journey: {
    '& button': {
      margin: '0.5rem 0 !important',
      height: '47px !important',
    },
  },
}));

enum MenuOption {
  // About = 'About',
  Team = 'Team',
  //Company_Stage = 'Company_Stage',
  Problem_And_Market = 'Problem_And_Market',
  //Product = 'Product',
  KPI = 'KPI',
}

interface ICompanyPrivateProfile {
  companyName: string;
  peoples: IPeople[];
  about: ICompanyAboutUs;
  problemAndMarket: IProblemAndMarket;
  canEditCompanyProfile: boolean;
}

export const MAX_NUM_LIST_ITEMS = 4;

const CompanyPrivateProfile: FunctionComponent<ICompanyPrivateProfile> = (
  props,
) => {
  const {
    companyName,
    peoples,
    about,
    problemAndMarket,
    canEditCompanyProfile,
  } = props;
  const { ecosystemName } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);

  const [note, setNote] = useState<ICompanySharedNote>(emptyNote());
  const [showNote, setShowNote] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<MenuOption>(
    MenuOption.Team,
  );
  const [patchingNote, setPatchingNote] = useState<boolean>(false);

  const [privateCompany, setPrivateCompany] =
    useState<ICompanyPrivateProfileInfo | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchPrivateCompany();
  }, []);

  async function fetchPrivateCompany() {
    setLoading(true);

    try {
      const data: ICompanyPrivateProfileInfo = await getData(
        COMPANY_PROFILE_PRIVATE_ROUTE,
        [
          {
            name: 'companyName',
            value: encodeURIComponent(companyName),
          },
          {
            name: 'ecosystemName',
            value: ecosystemName,
          },
        ],
      );

      setPrivateCompany(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function emptyNote(): ICompanySharedNote {
    return {
      id: -1,
      noteTitle: '',
      noteContent: '',
      noteTag: '',
      dateCreated: '',
      simplifiedDate: '',
    };
  }

  async function saveOnBlur(title: string, content: string) {
    if (note.noteTitle === '' && note.noteContent === '') return;
    if (note.id !== -1) return await patchNote(title, content);
    return addNote(title, content);
  }

  async function saveNote() {
    if (note.noteTitle === '' && note.noteContent === '') return;
    if (note.id !== -1)
      return await patchNote(note.noteTitle, note.noteContent);
    return addNote(note.noteTitle, note.noteContent);
  }

  async function addNote(title: string, content: string) {
    setSaveLoading(true);

    try {
      const data = await postData(COMPANY_NEW_NOTE_ROUTE, [
        { name: 'noteTitle', value: encodeURIComponent(title) },
        { name: 'noteContent', value: encodeURIComponent(content) },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'New note added',
      });

      updateNote(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setSaveLoading(false);
  }

  async function patchNote(title: string, content: string) {
    setPatchingNote(true);

    try {
      const data = await patchData(COMPANY_UPDATE_NOTE_ROUTE, [
        {
          name: 'noteId',
          value: note.id,
        },
        {
          name: 'companyName',
          value: encodeURIComponent(companyName),
        },
        {
          name: 'noteTitle',
          value: encodeURIComponent(title),
        },
        {
          name: 'noteContent',
          value: encodeURIComponent(content),
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
      updateNote(data, true);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setPatchingNote(false);
  }

  if (loading) return <Loading />;
  if (!privateCompany) return null;

  const {
    companyAdminDocs,
    companyGoals,
    companySharedDocs,
    companySharedNotes,
    keyPerformanceIndicators,
    resourceLinks,
    canUploadAdminDocs,
    journeyId,
  } = privateCompany;

  function updateNote(
    note: ICompanySharedNote,
    updated?: boolean,
    deleted?: boolean,
  ) {
    if (!privateCompany) return;

    const updatedNotes = deleted
      ? companySharedNotes.filter(({ id }) => id !== note.id)
      : updated
      ? companySharedNotes.map((n) => {
          if (note.id === n.id) {
            return note;
          }

          return n;
        })
      : [note, ...companySharedNotes];

    setPrivateCompany({
      ...privateCompany,
      companySharedNotes: updatedNotes,
    });

    if (deleted) {
      return setNote(updatedNotes[0]);
    }

    setNote(note);
  }

  function updateDocs(doc: ICompanyDoc, deleted: boolean) {
    if (!privateCompany) return;
    const { id, docType } = doc;

    const key =
      docType === 'resource'
        ? 'resourceLinks'
        : docType === 'admin'
        ? 'companyAdminDocs'
        : docType === 'shared'
        ? 'companySharedDocs'
        : '';

    let collection =
      docType === 'resource'
        ? privateCompany.resourceLinks
        : docType === 'admin'
        ? privateCompany.companyAdminDocs
        : docType === 'shared'
        ? privateCompany.companySharedDocs
        : [];

    if (!deleted) {
      collection = [doc, ...collection];
    } else {
      collection = collection.filter((c) => c.id !== id);
    }

    setPrivateCompany({
      ...privateCompany,
      [key]: collection,
    });
  }

  function updateGoals(goals: ICompanyGoal[]) {
    if (!privateCompany) return;

    setPrivateCompany({
      ...privateCompany,
      companyGoals: goals,
    });
  }

  function updateKPIs(kpis: ICompanyKPI[]) {
    if (!privateCompany) return;

    setPrivateCompany({
      ...privateCompany,
      keyPerformanceIndicators: kpis,
    });
  }

  function renderMenu() {
    return [...Object.entries(MenuOption)].map(([key, value]) => (
      <Button
        key={key}
        className={clsx(classes.menuOption, {
          [classes.activeMenuOption]: key === activeSection,
        })}
        onClick={() => {
          setActiveSection(key as MenuOption);
          saveNote();
          setNote(emptyNote());
          setShowNote(false);
        }}>
        <Typography variant={'h6'}>
          {value.replaceAll('_', ' ').replace('And', '&')}
        </Typography>
      </Button>
    ));
  }

  function renderMainContent() {
    if (showNote) {
      if (!note) return;
      return (
        <CompanyEditNote
          saveNote={saveOnBlur}
          saveLoading={saveLoading || patchingNote}
          companyName={companyName}
          note={note}
          setNote={setNote}
          updateNoteDisplay={(
            n: ICompanySharedNote,
            updated?: boolean,
            deleted?: boolean,
          ) => updateNote(n, updated, deleted)}
        />
      );
    }

    // if (activeSection === MenuOption.About) {
    //   return (
    //     <CompanyAboutUs
    //       description={about.description}
    //       pitchVideoUrl={about.pitchVideoUrl}
    //       sideBySide={about.sideBySide}
    //     />
    //   );
    // }

    if (activeSection === MenuOption.Team) {
      return <CompanyPeople peoples={peoples} small={true} />;
    }

    if (activeSection === MenuOption.Problem_And_Market) {
      return <CompanyProblemAndMarket problemAndMarket={problemAndMarket} />;
    }

    if (activeSection === MenuOption.KPI) {
      return (
        <CompanyKPI
          keyPerformanceIndicators={keyPerformanceIndicators}
          companyName={companyName}
          updateKPIs={updateKPIs}
          canEditCompanyProfile={canEditCompanyProfile}
        />
      );
    }

    return null;
  }

  return (
    <>
      <Grid item xs={12} md={2} lg={2}>
        <Fade in={true} timeout={1000}>
          <div>
            {renderMenu()}
            {!!journeyId && (
              <div className={classes.journey}>
                <MainButton
                  text={'Journey'}
                  onClick={() => {
                    saveNote();
                    navigate('journey');
                  }}
                  startIcon={<AssignmentTurnedInIcon />}
                  type={ButtonType.FILLED}
                />
              </div>
            )}
          </div>
        </Fade>
      </Grid>
      <Grid item xs={12} md={7} lg={7}>
        <CompanyGoals
          goals={companyGoals}
          updateGoals={updateGoals}
          companyName={companyName}
          canEditCompanyProfile={canEditCompanyProfile}
        />
        {renderMainContent()}
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <CompanyWorkspace
          patchingNote={patchingNote}
          companyName={companyName}
          setNote={setNote}
          showNote={showNote}
          setShowNote={(show: boolean) => setShowNote(show)}
          sharedNotes={companySharedNotes}
          sharedDocs={companySharedDocs}
          updateDocs={updateDocs}
          canEditCompanyProfile={canEditCompanyProfile}
        />
        <div style={{ marginTop: '10px' }}>
          <CompanySection noPadding={true}>
            <div>
              <CompanyAdminDocuments
                companyName={companyName}
                documents={companyAdminDocs}
                updateDocs={updateDocs}
                seeMore={
                  privateCompany.companyAdminDocs.length > MAX_NUM_LIST_ITEMS
                }
                canUploadAdminDocs={canUploadAdminDocs}
              />
              <CompanyResourceLinks
                updateDocs={updateDocs}
                resourceLinks={resourceLinks}
                companyName={companyName}
                canEditCompanyProfile={canEditCompanyProfile}
              />
            </div>
          </CompanySection>
        </div>
      </Grid>
    </>
  );
};

export default CompanyPrivateProfile;
