import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Tooltip, Typography } from '@mui/material';
import { useJourneyContext } from './JourneyContext';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '95px',
    width: '100%',
    border: '1px solid #E0E0E0',
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'end',
    padding: '0.5rem 1.75rem 1.4rem 1.75rem',

    '& p': {
      color: '#969696 !important',
      fontSize: '0.95rem !important',
    },
  },
  name: {
    minWidth: '13.125rem',
    overflow: 'none',
    height: '3.125rem',
    borderRadius: '5px',
    position: 'absolute',
    left: '-15px',
    top: '-25px',
    backgroundColor: '#E0F2F1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1rem',

    '& h6': {
      fontSize: '18px',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  check: {
    position: 'absolute',
    right: '-12px',
    top: '10px',
    backgroundColor: '#A2F0CF',
    height: '1.875rem',
    width: '1.875rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      color: theme.palette.primary.main,
      height: '20px',
      width: '20px',
    },
  },
}));

interface IJourneyHeader {}

const JourneyHeader: FunctionComponent<IJourneyHeader> = (props) => {
  const { stage, isStageCompleted } = useJourneyContext();
  const classes = useStyles();

  return !stage ? null : (
    <div className={classes.root}>
      <div className={classes.name}>
        <Fade in={isStageCompleted()} timeout={400}>
          <Tooltip title={'Stage Completed'}>
            <div className={classes.check}>
              <CheckIcon />
            </div>
          </Tooltip>
        </Fade>
        <Typography variant={'h6'}>{stage.name}</Typography>
      </div>
      <Typography variant={'body1'}>
        {stage.description || 'No stage description'}
      </Typography>
    </div>
  );
};

export default JourneyHeader;
