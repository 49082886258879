import React, { FunctionComponent, useContext, useState } from 'react';
import { Box, /* Fade, Theme,*/ Typography, useTheme } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IAnnouncement, IListButton } from 'utils/models';
import {
  deleteData,
  HOME_DELETE_ANNOUNCEMENT_ROUTE,
  HOME_PIN_ANNOUNCEMENT_ROUTE,
  patchData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { updateHome } from 'utils/storage';
import EditIcon from '@mui/icons-material/Edit';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import EditAnnouncement from './EditAnnouncement';
import Loading from '../common/Loading';
import RichTextDisplay from '../common/RichTextDisplay';
import { acterioTheme } from 'theme';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';

interface IAnnouncementProps {
  announcement: IAnnouncement;
  announcements: IAnnouncement[];
  setAnnouncements: (announcement: IAnnouncement[]) => void;
}

const Announcement: FunctionComponent<IAnnouncementProps> = (props) => {
  const { announcement, announcements, setAnnouncements } = props;
  const {
    id,
    creatorId,
    creatorName,
    title,
    body,
    timestamp,
    picUrl,
    pinned,
    role,
    imageUrl,
    accessLevelIds,
    canUserEdit,
  } = announcement;
  const { ecosystemName } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { addAlert } = useContext(AlertContext);

  const buttonList: IListButton[] = [
    {
      name: 'Pin',
      onClick: () => pinAnnouncement(),
      icon: PushPinIcon,
    },
    {
      name: 'Edit',
      onClick: () => setShowEdit(true),
      icon: EditIcon,
    },
    {
      name: 'Delete',
      onClick: () => deleteAnnouncement(),
      icon: DeleteIcon,
      textColor: acterioTheme.warningRed,
    },
  ];

  const [showMenu, setShowMenu] = useState<HTMLElement | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function pinAnnouncement() {
    setLoading(true);
    setShowMenu(null);

    try {
      await patchData(HOME_PIN_ANNOUNCEMENT_ROUTE, [
        {
          name: 'id',
          value: id,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      const sortedAnnouncements = announcements
        .map((announcement) => {
          if (announcement.id === id) {
            return {
              ...announcement,
              pinned: !pinned,
            };
          }

          return announcement;
        })
        .sort((a, b) => {
          const ts =
            new Date(a.timestamp).getTime() > new Date(b.timestamp).getTime();
          return a.pinned === b.pinned ? 1 : !a.pinned ? 1 : ts ? 1 : -1;
        });

      setAnnouncements(sortedAnnouncements);
      updateHome({
        announcements: sortedAnnouncements,
      });

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Pinned announcement',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function deleteAnnouncement() {
    setLoading(true);
    setShowMenu(null);

    try {
      await deleteData(HOME_DELETE_ANNOUNCEMENT_ROUTE, [
        {
          name: 'id',
          value: id,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      const updatedAnnouncements = announcements.filter(
        (announcement) => announcement.id !== id,
      );
      setAnnouncements(updatedAnnouncements);
      updateHome({
        announcements: updatedAnnouncements,
      });

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Announcement deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return (
    <>
      <Box sx={{ padding: '0.5rem 0.5rem 1.25rem 0', position: 'relative' }}>
        <Box sx={{ margin: '0 1rem', paddingBottom: '0.75rem' }}>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                width: '104.2%',
                height: '120%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                top: 0,
                left: 0,
                zIndex: 4,
                marginTop: '-25px',
              }}>
              <Loading />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <Box
            sx={{ display: 'flex', cursor: 'pointer' }}
            onClick={() => navigate(`members/${creatorId}`)}>
            <Box
              sx={{
                marginRight: '1rem',
              }}>
              <img
                style={{
                  width: '40px',
                  height: '40px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                }}
                src={picUrl}
              />
            </Box>
            <Box sx={{ marginRight: '1.5rem' }}>
              <Typography variant={'h6'}>{creatorName}</Typography>
              <Typography
                variant={'body1'}
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  lineHeight: '1.313rem',
                  color: theme.palette.primary.main,
                }}>
                {role}
              </Typography>
            </Box>
            <AccessLevelTagCard accessLevelName={role} height={'1.5rem'} />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: -10,
            top: -5,
            marginRight: '15px',
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
          }}>
          {pinned && (
            <PushPinIcon
              sx={{
                color: theme.palette.primary.main,
                transform: 'rotate(30deg)',
                marginRight: '1rem',
              }}
            />
          )}
          {canUserEdit && (
            <MoreOptionsOverlay
              popoverAnchorEl={showMenu}
              setPopoverAnchorEl={setShowMenu}>
              <MoreOptionsButtons buttonList={buttonList} />
            </MoreOptionsOverlay>
          )}
        </Box>
        <Box>
          <Typography variant={'h5'}>{title}</Typography>
        </Box>
        <RichTextDisplay
          style={{
            marginLeft: '3rem',
          }}
          text={decodeURIComponent(body.replace(/%/g, '%25'))}
        />
        <Box sx={{ display: 'flex', justifyContent: 'end', marginY: '0.5rem' }}>
          <Typography
            variant={'body2'}
            sx={{
              fontSize: '0.9rem',
              color: '#333333',
              display: 'flex',
              fontWeight: '400',
              alignItem: 'right',
              lineHeight: '1.313rem',
              margin: '0.5rem 1rem 0.5rem 0',
            }}>
            {timestamp}
          </Typography>
        </Box>{' '}
        {imageUrl && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: { xs: '12.688rem', md: '16.375rem' },
            }}>
            <img
              src={imageUrl}
              alt='Image'
              style={{
                objectFit: 'cover',
                width: '75%',
                height: '100%',
              }}
            />
          </Box>
        )}
      </Box>
      {canUserEdit && (
        <EditAnnouncement
          open={showEdit}
          close={() => setShowEdit(false)}
          announcements={announcements}
          setAnnouncements={setAnnouncements}
          id={id}
          title={title}
          body={body}
          imageUrl={imageUrl}
          accessLevelIds={accessLevelIds}
        />
      )}
    </>
  );
};

export default Announcement;
