import React, { FunctionComponent } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ICompany } from 'utils/models';
import { Box } from '@mui/system';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import { naturalPallete, primary } from 'theme';
import TagDisplay from 'components/common/Tags/TagDisplay';
import { IconPicker } from 'components/common/IconPicker';
import { FlexBox } from '../../utils/styledComponents';

const CompanyCard: FunctionComponent<ICompany> = (props) => {
  const {
    companyName,
    templateName,
    logoUrl,
    companyFirstMainField,
    companyHeader,
    usedTags,
    color,
    bannerUrl,
  } = props;
  const navigate = useNavigate();

  const theme = useTheme();

  function renderCardMedia() {
    return (
      <CardMedia
        sx={{
          height: '6.25rem',
          display: 'flex',
          position: 'relative',
        }}>
        <CardMedia
          component='img'
          src={bannerUrl}
          alt='CompanyCard-Background'
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '0.5rem',
            position: 'relative',
            zIndex: 2,
          }}>
          <CardMedia
            component='img'
            src={logoUrl}
            alt='CompanyCard-Logo'
            sx={{
              width: '100%',
              height: '100%',
              maxWidth: '12.5rem',
              objectFit: 'contain',
            }}
          />
        </Box>
      </CardMedia>
    );
  }

  function renderHeaderContainer() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'noWrap',
          width: '100%',
        }}>
        <Typography
          variant={'h4'}
          sx={{
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
          }}>
          {companyName}
        </Typography>
      </Box>
    );
  }

  function renderDescription() {
    return (
      <Box
        sx={{
          height: '8rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          maxHeight: 'calc(1.6em * 3)',
        }}>
        {companyFirstMainField &&
          companyFirstMainField.richText !== undefined && (
            <Typography
              sx={{
                color: naturalPallete.natural3,
                fontSize: '0.875rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
              }}>
              {companyFirstMainField.richText}
            </Typography>
          )}
      </Box>
    );
  }
  function renderCompanyFields() {
    return (
      <FlexBox
        sx={{
          gap: '1.5rem',
          width: '100%',
        }}>
        {(companyHeader[0]?.text || companyHeader[0]?.localDate) && (
          <Box
            sx={{
              display: 'flex',
              gap: '0.625rem',
              alignItems: 'center',
              overflow: 'hidden',
            }}>
            <IconPicker
              name={companyHeader[0].icon}
              sx={{ color: theme.palette.primary.main, fontSize: '0.938rem' }}
            />
            <Tooltip
              title={
                companyHeader[0].text
                  ? companyHeader[0].text
                  : companyHeader[0].localDate?.toString()
              }>
              <Typography
                variant={'h6'}
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {companyHeader[0].text
                  ? companyHeader[0].text
                  : companyHeader[0].localDate?.toString()}
              </Typography>
            </Tooltip>
          </Box>
        )}
        {(companyHeader[1]?.text || companyHeader[1]?.localDate) && (
          <Box
            sx={{
              display: 'flex',
              gap: '0.625rem',
              alignItems: 'center',
              overflow: 'hidden',
            }}>
            <IconPicker
              name={companyHeader[1].icon}
              sx={{ color: theme.palette.primary.main, fontSize: '0.938rem' }}
            />
            <Tooltip
              title={
                companyHeader[1].text
                  ? companyHeader[1].text
                  : companyHeader[1].localDate?.toString()
              }>
              <Typography
                variant={'h6'}
                sx={{
                  color: naturalPallete.natural2,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {companyHeader[1].text
                  ? companyHeader[1].text
                  : companyHeader[1].localDate?.toString()}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </FlexBox>
    );
  }

  function renderTags() {
    return (
      <TagDisplay
        sx={{
          height: '3.75rem',
        }}
        tags={
          usedTags && usedTags.length > 0
            ? usedTags
            : [{ name: `${templateName}` }]
        }
      />
    );
  }

  return (
    <Card
      onClick={() => navigate(`${companyName}`)}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        backgroundColor: `${primary.pureWhite}`,
        boxShadow: '0px 20px 27px 0px #0000000D',
        borderRadius: '12px',
        marginTop: '1.25rem',
      }}>
      {renderCardMedia()}

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '1rem !important',
          gap: '0.8rem',
        }}>
        <AccessLevelTagCard
          accessLevelName={templateName}
          accessLevelColor={color || theme.palette.primary.main}
          height={'1.5rem'}
          sx={{
            paddingLeft: '0.8rem',
          }}
        />
        <FlexBox
          sx={{
            flexDirection: 'column',
            alignItems: 'start',
            gap: '0.8rem',
          }}>
          {renderHeaderContainer()}
          {renderDescription()}
        </FlexBox>
        <FlexBox
          sx={{
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'end',
            gap: '0.625rem',
            height: '100%',
          }}>
          {renderCompanyFields()}
          {renderTags()}
        </FlexBox>
      </CardContent>
    </Card>
  );
};

export default CompanyCard;
